import _ from "lodash";
import { m, detectBrowserLanguage } from "../utils/i18n";
import { evalExpression } from "../utils/parseUtils";

import Cookies from 'js-cookie';
const { v4: uuidv4 } = require('uuid');

const {
  REACT_APP_PRODUCT_CODE,
  REACT_APP_FILL_FORM_ENABLED,
  REACT_APP_COLORS,
  REACT_APP_STATIC_CONTENT_URL,
  REACT_APP_BACKEND_URL,
  REACT_APP_PDF_URL,
} = process.env;

const colors = REACT_APP_COLORS.split(",");

const siteParams = {
  logo_src: `${REACT_APP_STATIC_CONTENT_URL}/images/${REACT_APP_PRODUCT_CODE}-double_logo.png`,
  logo_alt: "Galaxy Sistemi Gestione Multizona",
  dark_bar_src: `${REACT_APP_STATIC_CONTENT_URL}/images/sfondo-${REACT_APP_PRODUCT_CODE}-logo_DEVICE_LANGUAGE.png`,
  img_base_url: `${REACT_APP_STATIC_CONTENT_URL}/images/`,
  pdf_url: REACT_APP_PDF_URL,

  config_userdata_url: `${REACT_APP_BACKEND_URL}/userdata`,
  config_province_url: `${REACT_APP_BACKEND_URL}/config/province`,
  config_data_url: `${REACT_APP_BACKEND_URL}/step_properties`,
  next_step_url: `${REACT_APP_BACKEND_URL}/nextstep`,
  prev_step_url: `${REACT_APP_BACKEND_URL}/prevstep`,
  config_wizard_url: `${REACT_APP_BACKEND_URL}/config/wizardconfiguration`,
  config_messages_url: `${REACT_APP_BACKEND_URL}/config/message`,

  theme_band_background_color: colors[0],
  theme_palette_primary_main: colors[1],
  theme_palette_secondary_main: colors[2],
  theme_overrides_MuiRadio_root_color: colors[3],
  theme_overrides_MuiRadio_colorSecondary_checkedcolor: colors[4],
  summary_background_color: colors[5],
  fill_form_enabled: REACT_APP_FILL_FORM_ENABLED.toLowerCase() === "true",

  // theme_band_background_color: "#1E1E1E",
  // theme_palette_primary_main: "#674896",
  // theme_palette_secondary_main: "#F1A948",
  // theme_overrides_MuiRadio_root_color: "#674896",
  // theme_overrides_MuiRadio_colorSecondary_checkedcolor: "#674896",
};

let configurationProvince = null;
let wizardConfiguration = null;

export const fetchConfigurationProvince = async () => {
  try {
    configurationProvince = await fetch(siteParams.config_province_url)
      .then((response) => {
        return response.json();
      })

      .then((responseJson) => {
        return responseJson;
      });
    return configurationProvince;
  } catch (e) {
    console.error(e);
  }
};

let userData = null;
export const fetchUserData = async () => {
  try {

    var cookieValue = Cookies.get('ckUserData');
    console.log(cookieValue); // Output: 'cookie
    console.log("************ Cookie *********");
    if (cookieValue === undefined || cookieValue === "undefined") { //https://bit.ly/3tf5kai
      // Generate a new UUID
      cookieValue = uuidv4();
      console.log("New Cookie");
      console.log(cookieValue);
      Cookies.set('ckUserData', cookieValue, { expires: 999 }); // Expires after 999 days
      console.log(cookieValue);
    } else {
      console.log("Existing Cookie");
      console.log(cookieValue);
    }

    var params = new URLSearchParams();
    params.append('ck', cookieValue);

    const siteParams_user_data_url = siteParams.config_userdata_url;

    userData = await fetch(`${siteParams_user_data_url}?${params.toString()}`)
      .then((response) => {
        return response.json();
      })

      .then((responseJson) => {
        return responseJson;
      });
    console.log(userData);  
    return userData;
  } catch (e) {
    console.error(e);
  }
};


export const fetchWizardConfiguration = async () => {
  console.log("fetchWizardConfiguration");

  try {
    wizardConfiguration = await fetch(siteParams.config_wizard_url)
      .then((response) => {
        return response.json();
      })

      .then((responseJson) => {
        return responseJson;
      });
    return wizardConfiguration;
  } catch (e) {
    console.error(e);
  }
};

export const getDataFilteredStep = async (navAction, formData, steps, step, setStepData) => {
  const replacePlaceholders = (str) => {
    let ret = str;

    //"Scegli il Kit di completamento per {{brand}} {{model}}"
    // let placeHolders = ret.match(/(?<={{)(.*?)(?=}})/g); // safari spacca tutto https://bit.ly/3e8zXTi

    // ret = "Scegli il Kit di completamento per {{brand}} {{model}}";
    let arr = ret.split("{{");
    let placeHolders = [];
    for (var i = 1; i < arr.length; i++) {
      let pos = arr[i].indexOf("}}");
      if (pos >= 0) {
        placeHolders.push(arr[i].substr(0, pos));
      }
    }

    _.forEach(placeHolders, function (p) {
      let val = formData[p];
      val = val ? val : "";
      // ret = ret.replaceAll(`{{${p}}}`, val);
      const regex = new RegExp("{{" + p + "}}");
      ret = ret.replace(regex, val, "g");
    });

    return ret;
  };



  let key = step.params.radioGroup.value;

  // prendi chiavi univoche (label/value ) e metti in lista options
  // - ricava filtri da formData
  // - filtra wizardData in base a filtri
  // - inserisce in lista options i valori della colonna per le righe rimanenti
  // const filters = _.omitBy(formData, _.isEmpty);


  // // Remove empty attributes, but preserve numeric and non-empty attributes
  // const filters = _.pickBy(formData, (value) => {
  //   return _.isNumber(value) || (!_.isEmpty(value) && !_.isNull(value) && !_.isUndefined(value));
  // });


  // Remove empty attributes, but preserve numeric and non-empty attributes
  const filters = _.pickBy(formData, (value, key) => {
    return _.isNumber(value) || (!_.isEmpty(value) && !_.isNull(value) && !_.isUndefined(value) && !_.includes(key, '_NOPOST_'));
  });




  console.log(filters);

  let data = [];

  const qs = Object.keys(filters)
    .map((key) => `${key}=${filters[key]}`)
    .join("&");

  // const url = `${siteParams.config_data_url}/${key}?${qs}`;

  let url = "";

  switch (navAction) {
    case "prev":
      url = `${siteParams.prev_step_url}?${qs}&current=${step.id}`;
      break;
    case "next":
      url = `${siteParams.next_step_url}?${qs}&current=${step.id}`;
      break;
    default:
      url = `${siteParams.config_data_url}/${key}?stepidx=0&${qs}`;
  }

  //POST -> problema poi con redirect BE
  // // load data for current step
  // const response = await fetch(url, {
  //   method: "POST", // Specify the request method as POST
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(filters), // Convert the data to JSON format and send in the request body
  // });
  // let resp  = await response.json();

  // GET
  // load data for current step
  console.log(`*${url}*`);
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(filters),
  });
  let resp  = await response.json();


  console.log(resp);
  // filteredStep.params.radioGroup.options = data.rows;


  data = resp.data;
  var candidateIdx = resp.stepidx;


  // var candidateIdx = step.id-1;
  // if (data[0].stepidx) {
  //   candidateIdx = data[0].stepidx
  // }
  // if (candidateIdx ===1) {
  //   candidateIdx =2;
  // }
  let filteredStep = _.cloneDeep(steps[candidateIdx]);

  // console.log(filteredStep);

  // const wizardData = configuratorData;

  const { title, subtitle } = filteredStep.params;
  const { id } = filteredStep;

  filteredStep.params.title = replacePlaceholders(m(title));
  filteredStep.params.subtitle = replacePlaceholders(m(subtitle));



  if (filteredStep.params.radioGroup) {
    key = filteredStep.params.radioGroup.value;

    let options = _.flatMap(data, (row, index, arr) => {
      let option = [];
      let confItemIndex = 0;
      let confItemKey = "";

      if (filteredStep.summaryItemType === "MULTICOLUMN") {
        _.forEach(wizardConfiguration, function (item) {
          if (item.step === id && item.key.startsWith("column")) {
            confItemIndex = parseInt(item.key.split("column")[1].split(".")[0]);
            confItemKey = item.key.split(".")[1];

            if (option.length < confItemIndex) {
              option.push({});
            }

            switch (confItemKey) {
              // es. column1.label
              case "label":
                option[confItemIndex - 1].label = m(item.val);
                option[confItemIndex - 1].img_alt = m(item.val);
                option[confItemIndex - 1].value = m(item.val);
                break;
              // es. column1.key
              case "key":
                option[confItemIndex - 1].columnKey = item.val;
                option[confItemIndex - 1].code = row[item.val];
                option[confItemIndex - 1].img_src = row[`${item.val}img`];
                break;
              // es. column1.img
              // case "img":
              //   option[confItemIndex - 1].img_src = item.val;
              //   break;
              default:
                console.log(`ERROR!! Unknown key ${item.key}`);
            }
          }
        });

        // rimuove option se non sono valorizzate in colonna
        option = _.filter(option, function (o) {
          return row[o.columnKey] !== "";
        });
      } else {
        option = [
          {
            label: _.has(row, `${key}label`)
              ? m(row[`${key}label`])
              : m(row[key]),
            value: _.has(row, `${key}value`)
              ? m(row[`${key}value`])
              : m(row[key]),
            // value: _.has(row, `${key}value`) ? row[`${key}value`] : row[key],
            img_src: _.has(row, `${key}img`) ? row[`${key}img`] : null,
            img_alt: _.has(row, `${key}img`) ? m(row[key]) : null,
            infoText: _.has(row, `${key}popover`)
              ? m(row[`${key}popover`])
              : null,
            description: _.has(row, `${key}description`)
              ? m(row[`${key}description`])
              : null,
            nbText: _.has(row, `${key}nbtext`) ? m(row[`${key}nbtext`]) : null,
            imgDisclaimer: _.has(row, `${key}imgdisclaimer`)
              ? m(row[`${key}imgdisclaimer`])
              : null,
            group: _.has(row, `${key}group`) ? row[`${key}group`] : null,
            code: _.has(row, `${key}code`) ? row[`${key}code`] : null,
            price: _.has(row, `${key}price`) ? row[`${key}price`] : null,
            jumptostep: _.has(row, `${key}jumptostep`)
              ? row[`${key}jumptostep`]
              : null,
          },
        ];
      }
      return [...option];
      // } else {
      //   return {
      //     label: "",
      //   };
      // }
    });
    
    if (filteredStep.params?.radioGroup?.cards) {
      const code = filteredStep.params.radioGroup.code;
      let cards = [...options];
      const groupedData = cards.reduce((result, item) => {
        const index = result.findIndex((group) => group.code === item.code);
        // const cat = item.code;
        // delete item.code;
        const option = {
          label:item.label,
          value:`${code}__qty__${item.value}`             
          // value:item.value
        }
        if (index === -1) {
          result.push({
            code: item.code,
            group: item.group,
            img_alt: item.img_alt,
            img_src: item.img_src,
            imgDisclaimer: item.imgDisclaimer,
            description: item.description,
            infoText: item.infoText,
            jumptostep: item.jumptostep,
            nbText: item.nbText,
            options: [option],
          });
        } else {
          result[index].options.push(option);
        }
        return result;
      }, []);

      console.log(groupedData);
      filteredStep.params.radioGroup.cards = groupedData;

    } else {
      filteredStep.params.radioGroup.options = options;

    }

  }
  




// if (filteredStep.params.radioGroup) {
//   filteredStep.params.radioGroup.options = options;
// }

setStepData(_.cloneDeep(filteredStep));
  // } else {
  //   setStepData(_.cloneDeep(filteredStep));
  //   // setStepData(filteredStep);
  // }
};

export const getSiteParams = () => {
  return siteParams;
};

export const resetNextStepsFormData = (steps, step, formData) => {
  const stepsToReset = _.filter(steps, function (o) {
    return parseInt(o.id) > parseInt(step.id);
  });

  let updatedFormData = { ...formData };

  _.forEach(stepsToReset, function (step) {
    // console.log(step);
    let key = null;
    let prefixToRemove = null;
    switch (step.type) {
      case "Intro":
        break;
      case "DynamicGridStep":
        break;
      case "RadioGroupStep":
        key = step.params.radioGroup.value;
        updatedFormData[key] = "";
        if (_.has(updatedFormData, `${key}___code`)) {
          updatedFormData[`${key}___code`] = "";
        }
        if (_.has(updatedFormData, `${key}___price`)) {
          updatedFormData[`${key}___price`] = "";
        }
        if (_.has(updatedFormData, `${key}___jumptostep`)) {
          updatedFormData[`${key}___jumptostep`] = "";
        }
        break;

      case "CardsRadioGroupStep":
        key = step.params.radioGroup.value;
        updatedFormData[key] = "";
        if (_.has(updatedFormData, `${key}___code`)) {
          updatedFormData[`${key}___code`] = "";
        }
        if (_.has(updatedFormData, `${key}___price`)) {
          updatedFormData[`${key}___price`] = "";
        }
        if (_.has(updatedFormData, `${key}___jumptostep`)) {
          updatedFormData[`${key}___jumptostep`] = "";
        }
        break;

      // TODO proairsystem cassato sul nascere (cards con tendine)
      case "CardsSelectGroupStep":
        key = step.params.radioGroup.value;
        updatedFormData[key] = "";
        if (_.has(updatedFormData, `${key}___code`)) {
          updatedFormData[`${key}___code`] = "";
        }
        if (_.has(updatedFormData, `${key}___price`)) {
          updatedFormData[`${key}___price`] = "";
        }
        if (_.has(updatedFormData, `${key}___jumptostep`)) {
          updatedFormData[`${key}___jumptostep`] = "";
        }
        break;


      case "SingleCardMultiRadioGroupStep":
        key = step.params.radioGroup.value;
        updatedFormData[key] = "";
        if (_.has(updatedFormData, `${key}___code`)) {
          updatedFormData[`${key}___code`] = "";
        }
        if (_.has(updatedFormData, `${key}___price`)) {
          updatedFormData[`${key}___price`] = "";
        }
        if (_.has(updatedFormData, `${key}___jumptostep`)) {
          updatedFormData[`${key}___jumptostep`] = "";
        }
        break;

      case "CardsRadioGroupMulticolumnStep":
        key = step.params.radioGroup.value;
        updatedFormData[key] = "";
        if (_.has(updatedFormData, `${key}___code`)) {
          updatedFormData[`${key}___code`] = "";
        }
        if (_.has(updatedFormData, `${key}___price`)) {
          updatedFormData[`${key}___price`] = "";
        }
        if (_.has(updatedFormData, `${key}___jumptostep`)) {
          updatedFormData[`${key}___jumptostep`] = "";
        }

        break;

      case "CardsRadioGroupDoubleStep":
        key = step.params.radioGroup.value;
        updatedFormData[key] = "";
        if (_.has(updatedFormData, `${key}___code`)) {
          updatedFormData[`${key}___code`] = "";
        }
        if (_.has(updatedFormData, `${key}___price`)) {
          updatedFormData[`${key}___price`] = "";
        }
        if (_.has(updatedFormData, `${key}___jumptostep`)) {
          updatedFormData[`${key}___jumptostep`] = "";
        }

        break;

      case "CardsQuantityDoubleGroupStep":
        prefixToRemove = `${step.params.radioGroup.value}__qty`;
        updatedFormData = Object.entries(updatedFormData).reduce((acc, [key, value]) => {
            if (!key.startsWith(prefixToRemove)) {
                acc[key] = value;
            }
            return acc;
        }, {});

        break;

      case "CardsRadioGroupTripleStep":
        key = step.params.radioGroup.value;
        updatedFormData[key] = "";
        if (_.has(updatedFormData, `${key}___code`)) {
          updatedFormData[`${key}___code`] = "";
        }
        if (_.has(updatedFormData, `${key}___price`)) {
          updatedFormData[`${key}___price`] = "";
        }
        if (_.has(updatedFormData, `${key}___jumptostep`)) {
          updatedFormData[`${key}___jumptostep`] = "";
        }

        break;

      case "CardsRadioGroupCollectionStep":
        _.each(step.params.radioGroupCollection, function (group, i) {
          // Ipotizziamo max 10 gruppi (nel caso specifico 10 zone)
          const placeholder = "NNNNN";
          let k = null;
          for (var g = 1; g <= 10; g++) {
            k = group.value.replace("{{NNN}}", placeholder + g + placeholder);
            updatedFormData[k] = "";
            if (_.has(updatedFormData, `${k}___code`)) {
              updatedFormData[`${k}___code`] = "";
            }
            if (_.has(updatedFormData, `${key}___jumptostep`)) {
              updatedFormData[`${key}___jumptostep`] = "";
            }
          }
        });

        break;


      // TODO proairsystem  
      case "CardsQuantitiesGroupStep":
        prefixToRemove = `${step.params.radioGroup.value}__qty`;
        updatedFormData = Object.entries(updatedFormData).reduce((acc, [key, value]) => {
            if (!key.startsWith(prefixToRemove)) {
                acc[key] = value;
            }
            return acc;
        }, {});

        // updatedFormData = _.filter(updatedFormData, (obj) => {
        //   return Object.keys(obj).every(key => !key.startsWith(key_to_remove));
        // });

        // updatedFormData[step.params.radioGroup.name] = "";
        // updatedFormData[step.params.radioGroup.quantityName] = 0;
        // updatedFormData[`${step.params.radioGroup.name}__code`] = "";
        // updatedFormData[`${step.params.radioGroup.name}__description`] = "";
        break;


      // proair | appyair
      case "CardsQuantityGroupStep":
        updatedFormData[step.params.radioGroup.name] = "";
        updatedFormData[step.params.radioGroup.quantityName] = 0;
        updatedFormData[`${step.params.radioGroup.name}__code`] = "";
        updatedFormData[`${step.params.radioGroup.name}__price`] = "";
        updatedFormData[`${step.params.radioGroup.name}__description`] = "";
        break;

      //proair | idronico  
      case "CardsQuantityAndRadioGroupStep":
        updatedFormData[step.params.radioGroup.name] = "";
        updatedFormData[step.params.radioGroup.quantityName] = 0;
        updatedFormData[`${step.params.radioGroup.name}__code`] = "";
        updatedFormData[`${step.params.radioGroup.name}__price`] = "";
        updatedFormData[`${step.params.radioGroup.name}__description`] = "";

        key = step.params.optionGroup.value;
        updatedFormData[key] = "";
        if (_.has(updatedFormData, `${key}___code`)) {
          updatedFormData[`${key}___code`] = "";
        }
        if (_.has(updatedFormData, `${key}___price`)) {
          updatedFormData[`${key}___price`] = "";
        }
        if (_.has(updatedFormData, `${key}___jumptostep`)) {
          updatedFormData[`${key}___jumptostep`] = "";
        }

        break;

        
      case "MultiInputStep":
       
        // per retrocompatibilità si è agiunto questo flag in proaisystem
        // negli altri configuratori non c'è in gsheet -> default false
        if (step.resetNextStepsFormData) {
          step.params.fields.forEach(field => delete updatedFormData[field.name]);
        }

        break;

      case "ReviewPdf":

        //TODO if remember me checked 
        // if ((!_.isEmpty(userData)) && step.params.rememberMeActive){   <- and remember me checked

        const {
          privacyName,
          privacyName2,
          rememberMeName,
          categoryWidget,
          addressWidget
        } = step.params;


        if (!_.isEmpty(userData)){
          updatedFormData[categoryWidget.name] = userData["user_category"];
          updatedFormData["name"] = userData["name"];
          updatedFormData["email"] = userData["email"];
          updatedFormData[addressWidget.fullAddressName] = userData["full_address"];
          updatedFormData[addressWidget.postalCodeName] = userData["postal_code"];
          updatedFormData[addressWidget.postalCodeItem] = "";
          updatedFormData[addressWidget.provinceName] = userData["province"];

          const provinceItem = _.find(step.params.addressWidget.options, { value: userData["province"] });
          updatedFormData[addressWidget.provinceItem] = provinceItem;

          updatedFormData[rememberMeName] = userData["remember_me"];
          updatedFormData[privacyName] = userData["privacy"];
          updatedFormData[privacyName2]  = userData["privacy2"];
        } else {
          updatedFormData[categoryWidget.name] = "";
          updatedFormData["name"] = "";
          updatedFormData["email"] = "";
          updatedFormData[addressWidget.fullAddressName] = "";
          updatedFormData[addressWidget.postalCodeName] = "";
          updatedFormData[addressWidget.postalCodeItem] = null;
          updatedFormData[addressWidget.provinceName] = "";
          updatedFormData[addressWidget.provinceItem] = null;
          updatedFormData[rememberMeName] = false;
          updatedFormData[privacyName] = false;
          updatedFormData[privacyName] = false;
        }

        // updatedFormData["userCategory"] = "";
        // updatedFormData["name"] = "";
        // updatedFormData["email"] = "";
        // updatedFormData["fullAddress"] = "";
        // updatedFormData["postalCode"] = "";
        // updatedFormData["postalCodeItem"] = null;
        // updatedFormData["province"] = "";
        // updatedFormData["provinceItem"] = null;
        // updatedFormData["privacy"] = false;
        // updatedFormData["privacy2"] = false;

        break;

      default:
        // undefined, da rimuovere
        console.log(`Unknown type ${step.type}!`);
    }
  });

  // console.log(updatedFormData);

  // setFormValues({ ...updatedFormData });
  return { ...updatedFormData };
};

// const navigateNext = (event, reason) => {



/************************************** */
export const getPrevStepIndex = async (formData, step) => {

  // prendi chiavi univoche (label/value ) e metti in lista options
  // - ricava filtri da formData
  // - filtra wizardData in base a filtri
  // - inserisce in lista options i valori della colonna per le righe rimanenti
  const filters = _.omitBy(formData, _.isEmpty);
  console.log(filters);
  let data = [];
  const qs = Object.keys(filters)
    .map((key) => `${key}=${filters[key]}`)
    .join("&");

  const url = `${siteParams.prev_step_url}?${qs}&current=${step.id}`;
  console.log(`*${url}*`);

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(filters),
  });
  data = await response.json();

  console.log(data);
  return (data.prev);

};


export const navigatePrev = async (
  setNavAction,
  navigation,
  step,
  formData,
  setLoadingStepData
) => {

  // **************************************************************
  // Load Prev Step
  // **************************************************************
  // setLoadingStepData(true); // va qui perchè il next scatena referesh       
  // const prevIdx = await getPrevStepIndex(formData, step);
  // console.log("getPrevStepIndex");
  // navigation.go(prevIdx);

  setLoadingStepData(true); // va qui perchè il next scatena referesh       
  // navigation.go(prevIdx);
  setNavAction("prev");



};





/********************** */




export const getNextStepIndex = async (formData, step) => {

  // prendi chiavi univoche (label/value ) e metti in lista options
  // - ricava filtri da formData
  // - filtra wizardData in base a filtri
  // - inserisce in lista options i valori della colonna per le righe rimanenti
  const filters = _.omitBy(formData, _.isEmpty);

  console.log(filters);

  let data = [];

  const qs = Object.keys(filters)
    .map((key) => `${key}=${filters[key]}`)
    .join("&");

  const url = `${siteParams.next_step_url}?${qs}&current=${step.id}`;
  console.log(`*${url}*`);

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(filters),
  });
  data = await response.json();

  // data = exampleFetch();
  
  // data = fetchOHLC(url);


  console.log(data);
  return (data.next);

};


export const navigateNext = async (
// export const navigateNext =  (
  setNavAction,
  navigation,
  index,
  step,
  steps,
  formData,
  setMessageSnackbar,
  errors,
  setErrors,
  stepData,  
  setStepData,
  setLoadingStepData
) => {
  let nav = true;
  let snackbarMessage = null;
  let key = "";
  // const mandatory = step.params?.mandatory === true;
  // condizione dinamica 
  // es: formData['coveragetype___code'] !== 'tetto_grecato'
  // eslint-disable-next-line no-eval

  /* eslint no-eval: 0 */
  const mandatory = eval(step.params?.mandatory)=== true

  // **************************************************************
  // Roba per CardsRadioGroupCollectionStep -> non più usato
  // **************************************************************
  if (mandatory && step.type === "CardsRadioGroupCollectionStep") {
    const { radioGroupCollection } = step.params;

    let visibleGroup = false;
    let repeatGroup = 0;
    for (var irg = 0; irg < radioGroupCollection.length; irg++) {
      const rg = radioGroupCollection[irg];

      visibleGroup = evalExpression(rg.visibleIf, formData);

      repeatGroup = 0;
      if (visibleGroup) {
        repeatGroup = evalExpression(rg.repeat, formData);
      }

      for (var i = 1; i <= repeatGroup; i++) {
        const placeholder = "NNNNN";
        key = rg.name.replace("{{NNN}}", placeholder + i + placeholder);
        if (_.isEmpty(formData[key])) {
          nav = false;
        }
      }
    }
  }
  // **************************************************************


  // **************************************************************
  // VALIDATE FORM
  // **************************************************************

  
  
  
  
  // TODO proairsystem
  if (["CardsQuantitiesGroupStep", "CardsQuantityDoubleGroupStep"].includes(step.type)) {
      // const regex = new RegExp(field.validationExp);
      // const regmsg = field.validationMsg;
      // nav = regex.test(formData[field.name]);
      
      // const validationExp = "undefined !== _.find(Object.entries(formData), ([key, value]) => key.includes('chronothermostat_MASTER_') && typeof value === 'number' && value > 0)";
      // const validationMsg = "Almeno un cronotermostato deve essere Master"

      const validationExp = step.params.validationExp;
      const validationMsg = step.params.validationMsg;


      nav = eval(validationExp);

      snackbarMessage = validationMsg;    

  }

  // if (step.type === "MultiInputStep") {
  //   step.params.fields.forEach(function (field) {
  //     // step.params.fields.forEach((field) => {
  //     const regex = new RegExp(field.validationExp);
  //     const regmsg = field.validationMsg;
  //     const showIfCondition = field.showIf;
  //     const show = evalExpression(showIfCondition, formData);

  //     if (show && !regex.test(formData[field.name])) {
  //       formValid = false;
  //       err[field.name] = regmsg;
  //     }
  //   });
  // }









  if (mandatory && step.type === "CardsQuantityGroupStep") {
    key = step.params.radioGroup.quantityValue;
    nav = formData[key] > 0;
  }

  if (
    mandatory &&
    step.type !== "CardsQuantitiesGroupStep" &&
    step.type !== "CardsQuantityDoubleGroupStep" &&
    step.type !== "CardsRadioGroupCollectionStep" &&
    step.type !== "CardsQuantityGroupStep"
  ) {
    key = step.params.radioGroup.value;
    nav = !_.isEmpty(formData[key]);
  }

  let formValid = true;
  var err = {};

  // fake data
  if (step.type === "MultiInputStep") {
    step.params.fields.forEach(function (field) {
      // step.params.fields.forEach((field) => {
      const regex = new RegExp(field.validationExp);
      const regmsg = field.validationMsg;
      const showIfCondition = field.showIf;
      const show = evalExpression(showIfCondition, formData);

      if (show && !regex.test(formData[field.name])) {
        formValid = false;
        err[field.name] = regmsg;
      }
    });
  }

  if (step.type === "DynamicGridStep") {
    if (formData["layout"].length === 0) {
      formValid = false;
    }
  }

  if (!formValid) {
    nav = false;
  }
  setErrors({ ...err });

  // **************************************************************


  // **************************************************************
  // Load Next Step
  // **************************************************************
  if (nav) {
      
    // const nextIdx = await getNextStepIndex(formData, step);
    
    console.log("getNextDataFilteredStep");
    // setLoadingResource(null);

    console.log("nav");

    key = step.params?.radioGroup?.value;
    setLoadingStepData(true); // va qui perchè il next scatena referesh 
    if (
      _.has(formData, `${key}___jumptostep`) &&
      !_.isEmpty(formData[`${key}___jumptostep`])
    ) {
      navigation.go(parseInt(formData[`${key}___jumptostep`]));
      // TODO GESTIRE
    } else {
      // navigation.go(nextIdx);
      setNavAction("next");
      // navigation.next();  
    }
    // setLoadingStepData(true); // va qui perchè il next scatena referesh 

    
    // const stepTmp = _.cloneDeep(steps[1])
    // await getDataFilteredStep(formData, stepTmp, setStepData);

    // navigation.next();
  } else {
    snackbarMessage = (snackbarMessage == null) ? m("common.error.step_incompleted") : snackbarMessage;
    setMessageSnackbar(snackbarMessage);
  }
};

export const getWizardSteps = (wizardConfiguration, fromStep = 1) => {
  const conf = wizardConfiguration;
  const stepIds = _.sortedUniq(
    conf.map((row, index) => {
      return row.step;
    })
  );

  const getConfigValue = (stepId, searchKey) => {
    const param = _.find(wizardConfiguration, {
      step: stepId,
      key: searchKey,
    });

    const ret = param ? param.val : null;
    return ret;
  };

  let steps = [];
  let defaultData = {};

  defaultData["lang"] = detectBrowserLanguage();

  // per ogni id
  _.forEach(stepIds, function (stepId) {
    // const stepType = _.find(wizardConfiguration.rows, {
    //   step: stepId,
    //   key: "type",
    // }).val;

    const stepType = getConfigValue(stepId, "type");

    // lego tipo widget
    //console.log(`Step: ${stepId} - type: ${stepType}`);

    // scorro prop tpl e sostituisco
    const tplStep = _.find(stepsTemplates, {
      type: stepType,
    });

    // let step = { ...tplStep };
    let step = _.cloneDeep(tplStep);
    let key = "";
    let label = "";
    let quantityKey = "";
    let msgUnavailable = null;

    step.id = stepId;
    step.params.title = getConfigValue(stepId, "title");
    step.params.subtitle = getConfigValue(stepId, "subtitle");

    switch (step.type) {
      case "Intro":
        step.params.buttonLabel = getConfigValue(stepId, "buttonLabel");
        break;

      case "DynamicGridStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "DYNAMICGRID";
        }

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;

        step.params.param1Label = m(getConfigValue(stepId, "param1Label"));
        step.params.param2Label = m(getConfigValue(stepId, "param2Label"));
        step.params.rowText1 = m(getConfigValue(stepId, "rowText1"));
        step.params.rowText2 = m(getConfigValue(stepId, "rowText2"));
        step.params.buttonAddRowLabel = m(
          getConfigValue(stepId, "buttonAddRowLabel")
        );

        defaultData[key] = [];

        break;
      case "RadioGroupStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "KEY";
        }

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.options = [];

        defaultData[key] = "";

        break;

      case "CardsRadioGroupStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.params.nbText = m(getConfigValue(stepId, "nbText"));
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        // step.params.mandatory =
        //   getConfigValue(stepId, "mandatory").toLowerCase() === "true";
        // gestione dinamica con eval 
        // es: formData['coveragetype___code'] !== 'tetto_grecato'
        step.params.mandatory =
          getConfigValue(stepId, "mandatory");

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "KEY";
        }

        if (getConfigValue(stepId, "summaryItemType") != null) {
          step.summaryItemType = getConfigValue(stepId, "summaryItemType");
        }

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.options = [];

        defaultData[key] = "";

        break;

      //TODO proairsystem
      case "CardsSelectGroupStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.params.nbText = m(getConfigValue(stepId, "nbText"));
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        // step.params.mandatory =
        //   getConfigValue(stepId, "mandatory").toLowerCase() === "true";
        // gestione dinamica con eval 
        // es: formData['coveragetype___code'] !== 'tetto_grecato'
        step.params.mandatory =
          getConfigValue(stepId, "mandatory");

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "KEY";
        }

        if (getConfigValue(stepId, "summaryItemType") != null) {
          step.summaryItemType = getConfigValue(stepId, "summaryItemType");
        }

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.options = [];

        defaultData[key] = "";

        break;

      case "SingleCardMultiRadioGroupStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "KEY";
        }

        step.additionalImageDx = getConfigValue(stepId, "additionalImageDx");
        let additionalTextDx = getConfigValue(stepId, "additionalTextDx");
        step.additionalTextDx = additionalTextDx
          ? m(additionalTextDx)
          : additionalTextDx;

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.img_src = getConfigValue(stepId, "img");
        step.params.radioGroup.img_alt = m(label);
        step.params.radioGroup.options = [];

        defaultData[key] = "";

        break;

      case "CardsRadioGroupMulticolumnStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");

        step.params.nbText = m(getConfigValue(stepId, "nbText"));
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "MULTICOLUMN";
        }

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.options = [];

        defaultData[key] = "";

        break;

      case "CardsRadioGroupDoubleStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "KEY";
        }

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.options = [];

        step.params.radioGroup.groupLeft.text = m(
          getConfigValue(stepId, "groupLeft.text")
        );
        step.params.radioGroup.groupLeft.img_src = getConfigValue(
          stepId,
          "groupLeft.img"
        );
        step.params.radioGroup.groupLeft.value = getConfigValue(
          stepId,
          "groupLeft.key"
        );
        step.params.radioGroup.groupRight.text = m(
          getConfigValue(stepId, "groupRight.text")
        );
        step.params.radioGroup.groupRight.img_src = getConfigValue(
          stepId,
          "groupRight.img"
        );
        step.params.radioGroup.groupRight.value = getConfigValue(
          stepId,
          "groupRight.key"
        );

        defaultData[key] = "";

        break;


      case "CardsQuantityDoubleGroupStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        step.params.nbText = m(getConfigValue(stepId, "nbText"));

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "MULTIQUANTITY";
          // step.summaryItemType = "MULTIQUANTITYDOUBLE";
        }        

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.code = key;        
        step.params.radioGroup.options = [];

        step.params.radioGroup.groupLeft.text = m(
          getConfigValue(stepId, "groupLeft.text")
        );
        step.params.radioGroup.groupLeft.img_src = getConfigValue(
          stepId,
          "groupLeft.img"
        );
        step.params.radioGroup.groupLeft.value = getConfigValue(
          stepId,
          "groupLeft.key"
        );
        step.params.radioGroup.groupRight.text = m(
          getConfigValue(stepId, "groupRight.text")
        );
        step.params.radioGroup.groupRight.img_src = getConfigValue(
          stepId,
          "groupRight.img"
        );
        step.params.radioGroup.groupRight.value = getConfigValue(
          stepId,
          "groupRight.key"
        );

        step.params.validationExp = getConfigValue(stepId, "form_validation_exp");
        step.params.validationMsg = m(getConfigValue(stepId, "form_validation_msg"))


        defaultData[key] = "";

        break;

      case "CardsRadioGroupTripleStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "KEY";
        }

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.options = [];

        step.params.radioGroup.groupLeft.text = m(
          getConfigValue(stepId, "groupLeft.text")
        );
        step.params.radioGroup.groupLeft.img_src = getConfigValue(
          stepId,
          "groupLeft.img"
        );
        step.params.radioGroup.groupLeft.value = getConfigValue(
          stepId,
          "groupLeft.key"
        );

        m(
          (step.params.radioGroup.groupLeft.nbText = getConfigValue(
            stepId,
            "groupLeft.nbText"
          ))
        );

        step.params.radioGroup.groupCenter.text = m(
          getConfigValue(stepId, "groupCenter.text")
        );
        step.params.radioGroup.groupCenter.img_src = getConfigValue(
          stepId,
          "groupCenter.img"
        );
        step.params.radioGroup.groupCenter.value = getConfigValue(
          stepId,
          "groupCenter.key"
        );
        step.params.radioGroup.groupCenter.nbText = m(
          getConfigValue(stepId, "groupCenter.nbText")
        );

        step.params.radioGroup.groupRight.text = m(
          getConfigValue(stepId, "groupRight.text")
        );
        step.params.radioGroup.groupRight.img_src = getConfigValue(
          stepId,
          "groupRight.img"
        );
        step.params.radioGroup.groupRight.value = getConfigValue(
          stepId,
          "groupRight.key"
        );
        step.params.radioGroup.groupRight.nbText = m(
          getConfigValue(stepId, "groupRight.nbText")
        );

        defaultData[key] = "";

        break;

      case "CardsRadioGroupCollectionStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "GROUPED";
        }

        step.params.summaryAddendum.visibleIf = getConfigValue(
          stepId,
          "summaryAddendum.visibleIf"
        );
        step.params.summaryAddendum.quantity = getConfigValue(
          stepId,
          "summaryAddendum.quantity"
        );
        step.params.summaryAddendum.text = m(
          getConfigValue(stepId, "summaryAddendum.text")
        );

        let groupLabel = null;
        let groupKey = null;
        // max 8 combinazioni
        const groupTpl = _.cloneDeep(step.params.radioGroupCollection[0]);
        let group = null;
        step.params.radioGroupCollection = [];
        for (var i = 1; i <= 8; i++) {
          const groupPrefix = `group${i}`;
          groupLabel = getConfigValue(stepId, `${groupPrefix}.label`);
          if (groupLabel) {
            groupKey = getConfigValue(stepId, `${groupPrefix}.key`);

            group = _.cloneDeep(groupTpl);

            group.label = m(groupLabel);
            group.name = groupKey;
            group.value = groupKey;

            group.visibleIf = getConfigValue(
              stepId,
              `${groupPrefix}.visibleIf`
            );
            group.repeat = getConfigValue(stepId, `${groupPrefix}.repeat`);

            group.img_src = getConfigValue(stepId, `${groupPrefix}.img`);
            group.img_alt = m(groupLabel);
            group.description = m(
              getConfigValue(stepId, `${groupPrefix}.description`)
            );
            group.firstItemSescriptionPostfix = m(
              getConfigValue(
                stepId,
                `${groupPrefix}.firstItemSescriptionPostfix`
              )
            );

            let groupOptionPrefix = null;
            let groupOptionLabel = null;
            let option = null;
            group.options = [];

            // max 10 opzioni colore
            for (var j = 1; j <= 10; j++) {
              groupOptionPrefix = `${groupPrefix}.option${j}`;
              groupOptionLabel = getConfigValue(
                stepId,
                `${groupOptionPrefix}.label`
              );
              if (groupOptionLabel) {
                option = {
                  label: m(groupOptionLabel),
                  img_src: getConfigValue(
                    stepId,
                    `${groupOptionPrefix}.thumbImg`
                  ),
                  value: m(
                    getConfigValue(stepId, `${groupOptionPrefix}.value`)
                  ),
                };
                group.options.push(_.cloneDeep(option));
              }
            }
            step.params.radioGroupCollection.push(_.cloneDeep(group));

            // Ipotizziamo max 10 gruppi (nel caso specifico 10 zone)
            const placeholder = "NNNNN";
            let k = null;
            for (var g = 1; g <= 10; g++) {
              k = groupKey.replace("{{NNN}}", placeholder + g + placeholder);
              defaultData[k] = "";
            }
          }
        }

        break;

      case "CardsQuantitiesGroupStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        step.params.nbText = m(getConfigValue(stepId, "nbText"));


        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "MULTIQUANTITY";
        }

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.quantityLabel = m(
          getConfigValue(stepId, "quantityLabel")
        );
        step.params.radioGroup.code = getConfigValue(stepId, "code");
        step.params.validationExp = getConfigValue(stepId, "form_validation_exp");
        step.params.validationMsg = m(getConfigValue(stepId, "form_validation_msg"))
        
        
        // quantityKey = getConfigValue(stepId, "quantityKey");
        // step.params.radioGroup.quantityName = quantityKey;
        // step.params.radioGroup.quantityValue = quantityKey;
        step.params.radioGroup.cards[0] = {
          img_src: getConfigValue(stepId, "img"),
          img_alt: m(label),
          description: m(getConfigValue(stepId, "description")),
          infoText: m(getConfigValue(stepId, "popover")),
        };

        // defaultData[key] = "";
        // defaultData[quantityKey] = 0;
        // defaultData[`${key}__code`] = "";
        // defaultData[`${key}__price`] = "";
        // defaultData[`${key}__description`] = "";

        break;


      case "CardsQuantityGroupStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "QUANTITY";
        }

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.quantityLabel = m(
          getConfigValue(stepId, "quantityLabel")
        );
        step.params.radioGroup.code = getConfigValue(stepId, "code");
        step.params.radioGroup.price = getConfigValue(stepId, "price");
        quantityKey = getConfigValue(stepId, "quantityKey");
        step.params.radioGroup.quantityName = quantityKey;
        step.params.radioGroup.quantityValue = quantityKey;
        step.params.radioGroup.cards[0] = {
          img_src: getConfigValue(stepId, "img"),
          img_alt: m(label),
          description: m(getConfigValue(stepId, "description")),
          infoText: m(getConfigValue(stepId, "popover")),
        };

        defaultData[key] = "";
        defaultData[quantityKey] = 0;
        defaultData[`${key}__code`] = "";
        defaultData[`${key}__price`] = "";
        defaultData[`${key}__description`] = "";

        break;

      case "CardsQuantityAndRadioGroupStep":
        key = getConfigValue(stepId, "dataKey");
        label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");

        msgUnavailable = getConfigValue(stepId, "message_unavailable");
        step.params.messageUnavailable = msgUnavailable
          ? m(msgUnavailable)
          : m("common.combination_unavailable");

        step.params.mandatory =
          getConfigValue(stepId, "mandatory").toLowerCase() === "true";

        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "QUANTITY_AND_KEY";
        }

        step.params.radioGroup.label = m(label);
        step.params.radioGroup.name = key;
        step.params.radioGroup.value = key;
        step.params.radioGroup.quantityLabel = m(
          getConfigValue(stepId, "quantityLabel")
        );
        step.params.radioGroup.code = getConfigValue(stepId, "code");
        step.params.radioGroup.price = getConfigValue(stepId, "price");
        quantityKey = getConfigValue(stepId, "quantityKey");
        step.params.radioGroup.quantityName = quantityKey;
        step.params.radioGroup.quantityValue = quantityKey;
        step.params.radioGroup.cards[0] = {
          img_src: getConfigValue(stepId, "img"),
          img_alt: m(label),
          description: m(getConfigValue(stepId, "description")),
          infoText: m(getConfigValue(stepId, "popover")),
        };

        defaultData[key] = "";
        defaultData[quantityKey] = 0;
        defaultData[`${key}__code`] = "";
        defaultData[`${key}__price`] = "";
        defaultData[`${key}__description`] = "";

        // OPTION
        const optionKey = getConfigValue(stepId, "optionDataKey");
        const optionLabel = getConfigValue(stepId, "optionDataLabel");
        step.params.optionNbText = m(getConfigValue(stepId, "optionNbText"));

        if (
          getConfigValue(stepId, "optionPutInSummary").toLowerCase() === "true"
        ) {
          step.optionSummaryKeyName = optionKey;
        }

        step.params.optionGroup.label = m(optionLabel);
        step.params.optionGroup.name = optionKey;
        step.params.optionGroup.value = optionKey;
        step.params.optionGroup.img_src = getConfigValue(stepId, "optionImg");
        step.params.optionGroup.img_alt = m(optionLabel);
        // step.params.optionGroup.options = [];
        step.params.optionGroup.optionCards[0] = {
          img_src: getConfigValue(stepId, "optionImg"),
          img_alt: m(optionLabel),
          description: m(getConfigValue(stepId, "optionDescription")),
          infoText: m(getConfigValue(stepId, "optionPopover")),
          nbText: m(getConfigValue(stepId, "optionNbText")),
          value: m(optionLabel),
          code: getConfigValue(stepId, "optionCode"),
        };

        defaultData[optionKey] = "";
        defaultData[`${optionKey}___code`] = "";

        break;

      case "MultiInputStep":
        key = getConfigValue(stepId, "dataKey");
        step.progress = getConfigValue(stepId, "progress");
        if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
          step.summaryKeyName = key;
          step.summaryItemType = "MULTIINPUT";
        }
        // per omogeneità con altri
        step.params.radioGroup.value = key;

        if (getConfigValue(stepId, "resetNextStepsFormData") && getConfigValue(stepId, "resetNextStepsFormData").toLowerCase() === "true") {
          step.resetNextStepsFormData= true;  // default false          
        }
        

        // TODO proairsystem
        //impostsre default empty fields altrimenti la regex va su undefined



        const popover = getConfigValue(stepId, "popover");

        step.params.infoText = popover ?  m(popover) : null;
        step.params.fields = [];
        for (var ii = 0; ii < 100; ii++) {
          const fieldPrefix = `field${_.padStart(ii, 2, "0")}_`;
          const fieldType = getConfigValue(stepId, `${fieldPrefix}type`);
          let field = null;
          switch (fieldType) {
            case "video":
              field = {
                type: fieldType,
                src: m(getConfigValue(stepId, `${fieldPrefix}src`)),
                showIf: getConfigValue(stepId, `${fieldPrefix}showif`),
              };
              step.params.fields.push({ ...field });
              break;                 
            case "text":
              field = {
                type: fieldType,
                name: getConfigValue(stepId, `${fieldPrefix}name`),
                label: m(getConfigValue(stepId, `${fieldPrefix}label`)),
                showIf: getConfigValue(stepId, `${fieldPrefix}showif`),
                validationExp: getConfigValue(
                  stepId,
                  `${fieldPrefix}validation_exp`
                ),
                validationMsg: m(
                  getConfigValue(stepId, `${fieldPrefix}validation_msg`)
                ),
                mandatory:
                  getConfigValue(
                    stepId,
                    `${fieldPrefix}mandatory`
                  ).toLowerCase() === "true",
              };
              step.params.fields.push({ ...field });
              break;

            case "list":
              let options = [];
              for (var jj = 0; jj < 100; jj++) {
                const optionPrefix = `${fieldPrefix}opt${_.padStart(
                  jj,
                  2,
                  "0"
                )}_`;

                const optValue = getConfigValue(stepId, `${optionPrefix}value`);
                if (optValue) {
                  const optLabel = m(
                    getConfigValue(stepId, `${optionPrefix}label`)
                  );
                  options.push({
                    label: optLabel,
                    value: optValue,
                  });
                } else {
                  break;
                }
              }

              field = {
                type: fieldType,
                name: getConfigValue(stepId, `${fieldPrefix}name`),
                value: getConfigValue(stepId, `${fieldPrefix}name`),
                label: m(getConfigValue(stepId, `${fieldPrefix}label`)),
                showIf: getConfigValue(stepId, `${fieldPrefix}showif`),
                validationExp: getConfigValue(
                  stepId,
                  `${fieldPrefix}validation_exp`
                ),
                validationMsg: m(
                  getConfigValue(stepId, `${fieldPrefix}validation_msg`)
                ),

                mandatory:
                  getConfigValue(
                    stepId,
                    `${fieldPrefix}mandatory`
                  ).toLowerCase() === "true",
                options: [...options],
              };
              step.params.fields.push({ ...field });
              break;

            default:
              // todo interrompi for
              break;
          }
          if (fieldType == null) {
            break;
          }
        }

        break;

      // id: "..nn",
      // type: "MultiInputStep",
      // summaryItemType: null,
      // filterBy: false,
      // progress: 10,
      // params: {
      //   title: "",
      //   subtitle: "Scarica il PDF",
      //   fields: [
      //     {
      //       type: "input",
      //       name: "",
      //       value: "",
      //       label: "",
      //       mandatory: true,
      //     },
      //     {
      //       type: "list",
      //       name: "",
      //       value: "",
      //       label: "",
      //       mandatory: true,
      //       options: [
      //         { label: "..Padova", value: "..PD" },
      //         { label: "...Treviso", value: "...TV" },
      //       ],
      //     },
      //   ],
      // },

      case "ReviewPdf":
        // key = getConfigValue(stepId, "dataKey");
        // label = getConfigValue(stepId, "dataLabel");
        step.progress = getConfigValue(stepId, "progress");
        // per omogeneità con altri
        step.params.radioGroup.value = key;        

        // if (getConfigValue(stepId, "putInSummary").toLowerCase() === "true") {
        //   step.summaryKeyName = key;
        //   step.summaryItemType = "QUANTITY";
        // }

        step.params.categoryWidget.label = m(
          getConfigValue(stepId, "categoryLabel")
        );

        let optionsArray = m(getConfigValue(stepId, "categoryOptions"));

        step.params.categoryWidget.options = optionsArray.map((item) => {
          return { label: item, value: item };
        });

        step.params.addressWidget.fullAddressLabel = m(
          getConfigValue(stepId, "addressLabel")
        );

        step.params.addressWidget.postalCodeLabel = m(
          getConfigValue(stepId, "postalCodeLabel")
        );

        step.params.addressWidget.provinceLabel = m(
          getConfigValue(stepId, "provinceLabel")
        );
        step.params.addressWidget.options = configurationProvince.map((item) =>
          _.pick(item, ["label", "value"])
        );
        step.params.privacyLabel = m(getConfigValue(stepId, "privacyLabel"));
        step.params.privacyLink = getConfigValue(stepId, "privacyLink");
        step.params.privacyLabel2 = m(getConfigValue(stepId, "privacyLabel2"));
        step.params.privacyLink2 = getConfigValue(stepId, "privacyLink2");
        step.params.buttonLabel = m(getConfigValue(stepId, "buttonLabel"));

        if ((getConfigValue(stepId, "rememberMeActive")) && (getConfigValue(stepId, "rememberMeActive").toLowerCase() === "true")) {
          step.params.rememberMeActive = true;
          step.params.rememberMeLabel = m(getConfigValue(stepId, "rememberMeLabel"));
          step.params.rememberMeLink = getConfigValue(stepId, "rememberMeLink");
        }
        // step.params.currentUserData = userData;


        defaultData["userCategory"] = "";
        defaultData["name"] = "";
        defaultData["email"] = "";
        defaultData["fullAddress"] = "";
        defaultData["postalCode"] = "";
        defaultData["province"] = "";
        defaultData["provinceItem"] = null;
        defaultData["privacy"] = false;
        defaultData["privacy2"] = false;

        break;
      default:
        // undefined, da rimuovere
        console.log(`Unknown type ${step.type}!`);
    }

    // _.forOwn(step, function (value, key) {
    //   console.log(`     key: ${key} - value: ${value}`);
    // });

    if (!_.isEmpty(step)) {
      steps.push(_.cloneDeep(step));
    }
  });

  // console.log(JSON.stringify(steps));

  return [steps, defaultData];
};

const stepsTemplates = [
  {
    id: "..nn",
    type: "Intro",
    summaryItemType: null,
    // putInSummary: false,
    summaryKeyName: null,
    filterBy: false,
    params: {
      title: "...Title...",
      subtitle: "...Subtitle...",
      buttonLabel: "...INIZIA",
    },
  },
  {
    id: "..nn",
    type: "DynamicGridStep",
    summaryItemType: null,
    // putInSummary: false,
    summaryKeyName: null,
    filterBy: false,
    progress: 0,
    params: {
      title: "...Title...",
      subtitle: "...Subtitle...",
      mandatory: true,
      param1Label: "....param1Label",
      param2Label: "....param2Label",
      rowText1: "...rowText1",
      rowText2: "...rowText2",
      buttonAddRowLabel: "Aggiungi",
      radioGroup: {
        label: "...Grid",
        name: "...grid",
        value: "...grid",
      },
    },
  },

  {
    id: "..nn",
    type: "RadioGroupStep",
    summaryItemType: "KEY",
    // putInSummary: true,
    // groupedInSummary: false,
    summaryKeyName: null,
    filterBy: true,
    progress: 0,
    params: {
      title: "...Title...",
      subtitle: "...Subtitle...",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      radioGroup: {
        label: "Model",
        name: "model",
        value: "model",
        options: [
          { label: "FBS-A35/50", value: "FBS-A35/50" },
          { label: "FBD5", value: "FBD5" },
          { label: "BFHD", value: "BFHD" },
          { label: "MFND", value: "MFND" },
          { label: "NHG-3", value: "NHG-3" },
          { label: "FSDK", value: "FSDK" },
        ],
      },
    },
  },

  {
    id: "..nn",
    type: "CardsRadioGroupStep",
    summaryItemType: "KEY",
    summaryKeyName: null,
    filterBy: true,
    progress: 0,
    params: {
      title: "...Title...",
      subtitle: "...Subtitle...",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      nbText: "...NB:...",
      radioGroup: {
        label: "Centralina",
        name: "centralina",
        value: "centralina",
        options: [
          {
            img_src:
              "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
            img_alt: "Polaris 3X",
            label: "Polaris 3X",
            value: "polaris-3x",
            infoText: "Lore Ipsum Polaris 3X",
            nbText: "... NB Card",
            imgDisclaimer: "...imgdisclaimer Card...",
          },
          {
            img_src:
              "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
            img_alt: "Polaris 4X",
            label: "Polaris 4X",
            value: "polaris-4x",
            infoText: "Lore Ipsum Polaris 4X",
            nbText: "... NB Card",
            imgDisclaimer: "...imgdisclaimer Card...",
          },
        ],
      },
    },
  },
  

  {
    id: "..nn",
    type: "CardsSelectGroupStep",
    // putInSummary: true,
    // groupedInSummary: false,
    summaryItemType: "QUANTITY",
    summaryKeyName: "..rele",
    filterBy: false,
    progress: 0,
    params: {
      title: "",
      subtitle: "..Scegli gli accessori",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      radioGroup: {
        label: "...Modulo Relè",
        name: "...rele",
        value: "...rele",
        code: "..code",
        quantityLabel: "...Quantità",        
        cards: [
          {
            img_src:
              "...https://drive.google.com/uc?export=view&id=10RY2L9Sr7UlbU4kZT6eVMqSxjG9F8uac",
            img_alt: "...Modulo Relè per impianti radianti e per fancoi",
            description: "...Modulo Relè per impianti radianti e per fancoi",
            infoText: "...Lorem Ipsum Rele",

            options: [
              { label: "...label1", name: "code1" },
              { label: "...label1", name: "code1" },
            ],
          },
        ],
      },
    },
  },

  {
    id: "..nn",
    type: "SingleCardMultiRadioGroupStep",
    summaryItemType: "KEY",
    summaryKeyName: null,
    filterBy: true,
    additionalImageDx: "...addtional image...",
    additionalTextDx: "...additional text...",
    progress: 0,
    params: {
      title: "...Title...",
      subtitle: "...Subtitle...",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      radioGroup: {
        label: "Centralina",
        name: "centralina",
        value: "centralina",
        img_src:
          "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
        img_alt: "Polaris 3X",
        options: [
          {
            label: "Polaris 3X",
            value: "polaris-3x",
          },
          {
            label: "Polaris 4X",
            value: "polaris-4x",
          },
        ],
      },
    },
  },
  {
    id: "..nn",
    type: "CardsRadioGroupMulticolumnStep",
    summaryItemType: "MULTICOLUMN",
    summaryKeyName: null,
    filterBy: true,
    progress: 0,
    params: {
      title: "...Title...",
      subtitle: "...Subtitle...",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      nbText: "...NB:...",
      radioGroup: {
        label: "...Centralina",
        name: "...centralina",
        value: "...centralina",
        options: [
          {
            img_src:
              "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
            img_alt: "Polaris 3X",
            label: "Polaris 3X",
            value: "polaris-3x",
            infoText: "Lore Ipsum Polaris 3X",
          },
          {
            img_src:
              "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
            img_alt: "Polaris 4X",
            label: "Polaris 4X",
            value: "polaris-4x",
            infoText: "Lore Ipsum Polaris 4X",
          },
        ],
      },
    },
  },

  {
    id: "..nn",
    type: "CardsRadioGroupDoubleStep",
    summaryItemType: "KEY",
    summaryKeyName: null,
    filterBy: true,
    progress: 0,
    params: {
      title: "...Title...",
      subtitle: "...Subtitle...",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      radioGroup: {
        label: "...Centralina",
        name: "...centralina",
        value: "...centralina",
        groupLeft: {
          text: "...text..",
          img_src:
            "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
          value: "rf",
        },
        groupRight: {
          text: "...text..",
          img_src:
            "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
          value: "cablato",
        },
        options: [
          {
            img_src:
              "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
            img_alt: "XX Discovery 2X",
            label: "NR.XX DISCOVERY 2X",
            value: "XX Discovery 2X",
            infoText: "Lore Ipsum Polaris 3X",
            group: "rf",
          },
          {
            img_src:
              "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
            img_alt: "Polaris 4X",
            label: "Polaris 4X",
            value: "polaris-4x",
            infoText: "Lore Ipsum Polaris 4X",
            group: "cablato",
          },
        ],
      },
    },
  },
{
    id: "..nn",
    type: "CardsQuantityDoubleGroupStep",
    summaryItemType: "KEY",
    summaryKeyName: null,
    filterBy: true,
    progress: 0,
    params: {
      title: "...Title...",
      subtitle: "...Subtitle...",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      nbText: "...NB:...",
      validationExp: "...validationExp",
      validationMsg: "...validationMsg",
      radioGroup: {
        label: "...Centralina",
        name: "...centralina",
        value: "...centralina",
        groupLeft: {
          text: "...text..",
          img_src:
            "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
          value: "rf",
        },
        groupRight: {
          text: "...text..",
          img_src:
            "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
          value: "cablato",
        },
        options: [
          {
            img_src:
              "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
            img_alt: "XX Discovery 2X",
            label: "NR.XX DISCOVERY 2X",
            value: "XX Discovery 2X",
            infoText: "Lore Ipsum Polaris 3X",
            group: "rf",
          },
          {
            img_src:
              "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
            img_alt: "Polaris 4X",
            label: "Polaris 4X",
            value: "polaris-4x",
            infoText: "Lore Ipsum Polaris 4X",
            group: "cablato",
          },
        ],
      },
    },
  },

  {
    id: "..nn",
    type: "CardsRadioGroupTripleStep",
    summaryItemType: "KEY",
    summaryKeyName: null,
    filterBy: true,
    progress: 0,
    params: {
      title: "...Title...",
      subtitle: "...Subtitle...",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      radioGroup: {
        label: "...Centralina",
        name: "...centralina",
        value: "...centralina",
        groupLeft: {
          text: "...text..",
          img_src:
            "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
          value: "rf",
          nbText: "...NB:...",
        },
        groupCenter: {
          text: "...text..",
          img_src:
            "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
          value: "rf",
          nbText: "...NB:...",
        },
        groupRight: {
          text: "...text..",
          img_src:
            "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
          value: "cablato",
          nbText: "...NB:...",
        },
        options: [
          {
            img_src:
              "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
            img_alt: "XX Discovery 2X",
            label: "NR.XX DISCOVERY 2X",
            value: "XX Discovery 2X",
            infoText: "Lore Ipsum Polaris 3X",
            group: "rf",
          },
          {
            img_src:
              "https://drive.google.com/uc?export=view&id=1d2yJMiPfnbDSuAxvWTKxqaJPWMNSrGaf",
            img_alt: "Polaris 4X",
            label: "Polaris 4X",
            value: "polaris-4x",
            infoText: "Lore Ipsum Polaris 4X",
            group: "cablato",
          },
        ],
      },
    },
  },

  {
    id: "..nn",
    type: "CardsRadioGroupCollectionStep",
    // putInSummary: true,
    // groupedInSummary: true,
    summaryItemType: "GROUPED",
    summaryKeyName: null,
    filterBy: false,
    progress: 0,
    params: {
      title: "...Title...",
      subtitle: "...Subtitle...",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      summaryAddendum: {
        visibleIf: "...condiition",
        quantity: "...{{zone}}-1",
        text: "...LEM",
      },
      radioGroupCollection: [
        {
          label: "...Discovery 2X {{NNN}}",
          name: "...colorDiscovery_{{NNN}}",
          value: "...colorDiscovery_{{NNN}}",
          visibleIf: "...expression",
          repeat: "...expression",
          img_src:
            "...https://drive.google.com/uc?export=view&id=1l56cYevgZl57ZOZGmqmBpoIPJC35ozA4",
          img_alt: "...Discovery 2X {{NNN}}",
          description: "...Discovery 2X {{NNN}}",
          firstItemSescriptionPostfix: "... (master)",
          options: [
            { label: "...BIANCO LUMIX", value: "...Bianco Lumix" },
            { label: "...GRIGIO TITANIO", value: "...grey" },
            { label: "...NERO PROFONDO", value: "...black" },
          ],
        },
        {
          label: "Mini Discovery 2X {{NNN}}",
          name: "colorMiniDiscovery_{{NNN}}",
          value: "colorMiniDiscovery_{{NNN}}",
          visibleIf: "...expression",
          repeat: "...expression",
          img_src:
            "https://drive.google.com/uc?export=view&id=1vgbr4th-LjFPgDOjHGTmhoYRIz_d_9qP",
          img_alt: "Mini Discovery 2X {{NNN}}",
          description: "Mini Discovery 2X {{NNN}}",
          firstItemSescriptionPostfix: "",
          options: [
            { label: "BIANCO LUMIX", value: "Bianco Lumix" },
            { label: "GRIGIO TITANIO", value: "grey" },
            { label: "NERO PROFONDO", value: "black" },
          ],
        },
        {
          label: "Stealth 2X {{NNN}} ",
          name: "colorStealth_{{NNN}}",
          value: "colorStealth_{{NNN}}",
          visibleIf: "...expression",
          repeat: "...expression",
          img_src:
            "https://drive.google.com/uc?export=view&id=1vgbr4th-LjFPgDOjHGTmhoYRIz_d_9qP",
          img_alt: "Stealth {{NNN}}",
          description: "Stealth {{NNN}}",
          firstItemSescriptionPostfix: "... (master)",
          options: [
            { label: "BIANCO LUMIX", value: "Bianco Lumix" },
            { label: "GRIGIO TITANIO", value: "grey" },
            { label: "NERO PROFONDO", value: "black" },
          ],
        },
      ],
    },
  },

  {
    id: "..nn",
    type: "CardsQuantitiesGroupStep",
    // putInSummary: true,
    // groupedInSummary: false,
    summaryItemType: "QUANTITY",
    summaryKeyName: "..rele",
    filterBy: false,
    progress: 0,
    params: {
      title: "",
      subtitle: "..Scegli gli accessori",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      nbText: "...NB:...",
      validationExp: "...validationExp",
      validationMsg: "...validationMsg",
      radioGroup: {
        label: "...Modulo Relè",
        name: "...rele",
        value: "...rele",
        code: "..code",
        quantityLabel: "...Quantità",        
        cards: [
          {
            img_src:
              "...https://drive.google.com/uc?export=view&id=10RY2L9Sr7UlbU4kZT6eVMqSxjG9F8uac",
            img_alt: "...Modulo Relè per impianti radianti e per fancoi",
            description: "...Modulo Relè per impianti radianti e per fancoi",
            infoText: "...Lorem Ipsum Rele",

            options: [
              { label: "...label1", name: "code1" },
              { label: "...label1", name: "code1" },
            ],
          },
        ],
      },
    },
  },
  {
    id: "..nn",
    type: "CardsQuantityGroupStep",
    // putInSummary: true,
    // groupedInSummary: false,
    summaryItemType: "QUANTITY",
    summaryKeyName: "..rele",
    filterBy: false,
    progress: 0,
    params: {
      title: "",
      subtitle: "..Scegli gli accessori",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      radioGroup: {
        label: "...Modulo Relè",
        name: "...rele",
        value: "...rele",
        code: "..code",
        price: "...price",
        quantityLabel: "...Quantità",
        quantityName: "...releQuantity",
        quantityValue: "...releQuantity",
        cards: [
          {
            img_src:
              "...https://drive.google.com/uc?export=view&id=10RY2L9Sr7UlbU4kZT6eVMqSxjG9F8uac",
            img_alt: "...Modulo Relè per impianti radianti e per fancoi",
            description: "...Modulo Relè per impianti radianti e per fancoi",
            infoText: "...Lorem Ipsum Rele",
            options: [
              { label: "SI", value: "true" },
              { label: "NO", value: "false" },
            ],
          },
        ],
      },
    },
  },

  {
    id: "..nn",
    type: "CardsQuantityAndRadioGroupStep",
    // putInSummary: true,
    // groupedInSummary: false,
    summaryItemType: "QUANTITY",
    summaryKeyName: "..rele",
    optionSummaryItemType: "KEY",
    optionSummaryKeyName: null,
    filterBy: false,
    progress: 0,
    params: {
      title: "",
      subtitle: "..Scegli gli accessori",
      messageUnavailable: "... not in catalog ...",
      mandatory: true,
      optionNbText: "...optionNbText",

      radioGroup: {
        label: "...Modulo Relè",
        name: "...rele",
        value: "...rele",
        code: "..code",
        price: "...price",
        quantityLabel: "...Quantità",
        quantityName: "...releQuantity",
        quantityValue: "...releQuantity",
        cards: [
          {
            img_src:
              "...https://drive.google.com/uc?export=view&id=10RY2L9Sr7UlbU4kZT6eVMqSxjG9F8uac",
            img_alt: "...Modulo Relè per impianti radianti e per fancoi",
            description: "...Modulo Relè per impianti radianti e per fancoi",
            infoText: "...Lorem Ipsum Rele",
            options: [
              { label: "SI", value: "true" },
              { label: "NO", value: "false" },
            ],
          },
        ],
      },

      optionGroup: {
        label: "...Modulo Relè",
        name: "...rele",
        value: "...rele",
        code: "..code",
        price: "...price",
        optionCards: [
          {
            img_src:
              "...https://drive.google.com/uc?export=view&id=10RY2L9Sr7UlbU4kZT6eVMqSxjG9F8uac",
            img_alt: "...Modulo Relè per impianti radianti e per fancoi",
            description: "...Modulo Relè per impianti radianti e per fancoi",
            infoText: "...Lorem Ipsum Rele",
            options: [
              { label: "SI", value: "true" },
              { label: "NO", value: "false" },
            ],
          },
        ],
      },
    },
  },
  {
    id: "..nn",
    type: "ReviewPdf",
    summaryItemType: null,
    filterBy: false,
    progress: 0,
    params: {
      radioGroup: {
        value: "...key"
      },    
      title: "",
      subtitle: "Scarica il PDF",
      privacyName: "privacy",
      privacyValue: "privacy",
      privacyLabel: "...Accetto i termini di utilizzo e la Privacy Policy",
      privacyLink: "...https://loremipsum.io/privacy-policy/",
      privacyName2: "privacy2",
      privacyValue2: "privacy2",
      privacyLabel2: "...Accetto i termini di utilizzo e la Privacy Policy2",
      privacyLink2: "...2 https://loremipsum.io/privacy-policy/",
      rememberMeActive: false,
      rememberMeName: "rememberMe",
      rememberMeValue: "rememberMe",
      rememberMeLabel: "...Ricordami ",      
      rememberMeLink: "...3 https://loremipsum.io/privacy-policy/",
      buttonLabel: "...SCARICA",
      addressWidget: {
        fullAddressName: "fullAddress",
        fullAddressValue: "fullAddress",
        fullAddressLabel: "...Adddress",
        postalCodeName: "postalCode",
        postalCodeValue: "postalCode",
        postalCodeItem: "postalCodeItem",
        postalCodeLabel: "... postalCode Label",
        provinceName: "province",
        provinceValue: "province",
        provinceItem: "provinceItem",
        provinceLabel: "... Address Label",
        options: [
          { label: "..Padova", value: "..PD" },
          { label: "...Treviso", value: "...TV" },
        ],
      },

      categoryWidget: {
        label: "... Category",
        name: "userCategory",
        value: "userCategory",
        options: [
          { label: "...TERMOTECNICO", value: "...TERMOTECNICO" },
          { label: "...TERMOIDRAULICO", value: "...TERMOIDRAULICO" },
        ],
      },
    },
  },

  {
    id: "..nn",
    type: "MultiInputStep",
    summaryItemType: "MULTIINPUT",
    filterBy: false,
    progress: 10,
    resetNextStepsFormData: false,
    params: {
      radioGroup: {
        value: "...key"
      },
      title: "",
      subtitle: "Scarica il PDF",
      fields: [
        {
          type: "list",
          name: "orientation",
          value: "orientation",
          label: "Orientamento pannelli",
          mandatory: true,
          options: [
            { label: "..Orizzontale", value: "horizontal" },
            { label: "...Verticale", value: "vertical" },
          ],
        },

        {
          type: "text",
          name: "shortsidelength",
          label: "Lato corto (mm)",
          mandatory: true,
        },
        {
          type: "text",
          name: "longsidelength",
          label: "Lato lungo (mm)",
          mandatory: true,
        },
      ],
    },
  },
];
