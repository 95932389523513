import _ from "lodash";
import { getSiteParams } from "../configuration/wizardConfiguration";

// import messages from "../configuration/json/messages.json";
let messages = null;

export const fetchi18nMessages = async () => {
  console.log("fetchi18nMessages");
  const siteParams = getSiteParams();
  messages = await fetch(siteParams.config_messages_url)
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    });
};

export const detectBrowserLanguage = () => {
  let language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

  // just language, not locale: en-US -> en
  return language.split(/-|_/)[0];
};

export const m = (messageKey) => {
  const lang = detectBrowserLanguage();
  if (messageKey === undefined) {
    return "";
  }
  // replaceAll spacca safari

  // es. {{messages.intro.title}} -> intro.title
  // console.log(messageKey);
  if (messageKey == null) {
    console.log("messageKey null WTF");
  }
  let mKey = messageKey.replace(/\[\[/g, "").replace(/\]\]/g, "").trim();

  let row = _.find(messages, { key: mKey });
  let ret = messageKey;

  // fallback english
  ret = row && row["en"] ? row["en"] : ret;
  ret = row && row[lang] ? row[lang] : ret;

  // if arrray convert string to array and return array
  try {
    let arr = JSON.parse(ret);
    ret = _.isObject(arr) ? arr : ret;
  } catch (err) {}

  return ret;
};
