import React from "react";
import _ from "lodash";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { m } from "../utils/i18n";

export const AutocompleteList = ({
  formData,
  setForm,
  setFormValues,
  navigation,
  stepData,
  errors,
  setErrors,
  listWidget,
  defaultValue,
}) => {
  // const classes = useStyles();
  // const { widget } = stepData;

  // TODO generalizzare tendina category

  // const widget = stepData.params.categoryWidget;
  const widget = listWidget;

  // const widget = {
  //   label: m("common.input.userCategory"),
  //   name: "userCategory",
  //   value: "userCategory",
  //   // item: "userCategoryItem",

  //   options: [
  //     { label: "TERMOTECNICO", value: "TERMOTECNICO" },
  //     { label: "TERMOIDRAULICO", value: "TERMOIDRAULICO" },
  //     { label: "ELETTRICO", value: "ELETTRICO" },
  //     { label: "COSTRUTTORE", value: "COSTRUTTORE" },
  //     { label: "PROGETTISTA", value: "PROGETTISTA" },
  //     { label: "PRIVATO", value: "PRIVATO" },
  //     { label: "ALTRO", value: "ALTRO" },
  //   ],
  // };

  const { options } = widget;

  const handleChange = (newValue) => {
    let updatedFormData = { ...formData };
    updatedFormData[widget.value] = newValue ? newValue.value : "";
    setFormValues({ ...updatedFormData });
  };

  const handleFocus = (event) => {
    let err = { ...errors };
    err[event.target.name] = "";
    setErrors(err);
  };

  const optionSelected = (option, value) => {
    return _.isEqual(option, value);
  };

  // default value #remember_me  
  const def = typeof defaultValue != "undefined" ? _.find(options, { value: defaultValue }) : null;

  return (
    <Autocomplete
      id={widget.name}
      options={options}
      getOptionLabel={(option) => option.label}
      // value={formData[widget.item]}
      onChange={(event, newValue) => {
        handleChange({ ...newValue });
      }}
      defaultValue={def}
      getOptionSelected={(option, value) => optionSelected(option, value)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={widget.label}
          name={widget.name}
          required
          margin="normal"
          variant="outlined"
          error={Boolean(errors[widget.name])}
          helperText={errors[widget.name]}
          onFocus={handleFocus}
        />
      )}
    />
  );
};
