import React from "react";
import Container from "@material-ui/core/Container";
// import Button from "@material-ui/core/Button";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
// import FormLabel from "@material-ui/core/FormLabel";
import ReactMarkdown from "react-markdown";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { Box } from "@material-ui/core";
import { m } from "../../utils/i18n";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing(5),
  },
}));

export const RadioGroupStep = ({
  formData,
  setForm,
  setFormValues,
  navigation,
  stepData,
}) => {
  const { radioGroup } = stepData.params;
  const { options } = radioGroup;
  const classes = useStyles();

  const handleChange = (event) => {
    let updatedFormData = { ...formData };

    updatedFormData[radioGroup.value] = event.target.value;

    
    // se c'è codice item lo mettiamo in formData
    const opt = _.find(radioGroup.options, { value: event.target.value });
    if (_.has(opt, "code") && !_.isEmpty(opt.code)) {
      updatedFormData[`${radioGroup.name}___code`] = opt.code;
    }

    // const ud = resetStepFormData(steps, stepData, updatedFormData);

    setFormValues({ ...updatedFormData });

    // resetStepFormData(steps, stepData, setFormValues, formData);

    // setAge(event.target.value);
  };


  return (
    <Container>
      <Box textAlign="center">
      
        <FormControl className={classes.formControl}>
          {/* <InputLabel id="select-label">
            {m("common.select.placeholder")}
          </InputLabel> */}

          {options.length === 0 ? (
            <Grid
              item
              container
              spacing={1}
              alignContent="center"
              alignItems="flex-start"
              justify="space-around"
            >
              <Box
                display="flex"
                width="50%"
                justifyContent="center"
                m={2}
                px={2}
              >
                <Typography variant="h6" align="center">
                  <ReactMarkdown
                    children={stepData.params.messageUnavailable}
                  />
                </Typography>
              </Box>
            </Grid>
          ) : (
          <Select
            labelId={radioGroup.label}
            id={radioGroup.name}
            value={formData[radioGroup.value]}
            onChange={handleChange}
            displayEmpty
            className={classes.selectEmpty}

            IconComponent={(props) => (<ArrowDropDownIcon fontSize="large" {...props}/>)}
          >
            <MenuItem value="">
              <em>{m("common.select.placeholder")}</em>
            </MenuItem>
            {options.map((data, index) => {
              return (
                <MenuItem key={data.value} value={data.value}>
                  {data.label}{" "}
                </MenuItem>
              );
            })}
          </Select>

          )}



        </FormControl>
      </Box>
    </Container>
  );
};

/*
    <Container maxWidth="xs">
      <FormControl required className={classes.formControl}>
        <Select
          labelId={radioGroup.label}
          id={radioGroup.name}
          value={radioGroup.value}
          onChange={handleChange}
          className={classes.selectEmpty}
        >
          {options.map((data, index) => {
            return <MenuItem value={data.value}>{data.label} </MenuItem>;
          })}
        </Select>
      </FormControl>
    </Container>

*/

/*
// Versione  con option da scommentare, mettere in un box che fa scrollare e attivare per prossimi configuratori
export const RadioGroupStep = ({ formData, setForm, navigation, stepData }) => {
  const { radioGroup } = stepData.params;
  const { options } = radioGroup;

  return (
    <Container maxWidth="xs">
      <FormControl component="fieldset">
        
        <RadioGroup
          aria-label={radioGroup.label}
          name={radioGroup.name}
          value={formData[radioGroup.value]}
          onChange={setForm}
        >
          {options.map((data, index) => {
            return (
              <FormControlLabel
                key={data.value}
                value={data.value}
                control={<Radio />}
                label={data.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Container>
  );
};

*/
