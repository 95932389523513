// import React from "react";
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { fetchi18nMessages, detectBrowserLanguage } from "../utils/i18n";
// import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import useMediaQuery from "@material-ui/core/useMediaQuery";

// import { makeStyles } from "@material-ui/core/styles";

import ReactMarkdown from "react-markdown";

import Button from "@material-ui/core/Button";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

// import { useForm, useStep } from "react-hooks-helper";
import { useForm, useStep } from "../react-hooks-helper";

import { ProgressWithValueLabel } from "./ProgressWithValueLabel";
import { SummaryCard } from "./SummaryCard";
import { MessageSnackbar } from "./MessageSnackbar";

import { Intro } from "./stepForm/Intro";
import { DynamicGridStep } from "./stepForm/DynamicGridStep";
import { RadioGroupStep } from "./stepForm/RadioGroupStep";
import { CardsRadioGroupStep } from "./stepForm/CardsRadioGroupStep";
import { CardsSelectGroupStep } from "./stepForm/CardsSelectGroupStep";
import { SingleCardMultiRadioGroupStep } from "./stepForm/SingleCardMultiRadioGroupStep";
import { CardsRadioGroupDoubleStep } from "./stepForm/CardsRadioGroupDoubleStep";
import { CardsQuantityDoubleGroupStep } from "./stepForm/CardsQuantityDoubleGroupStep";
import { CardsRadioGroupTripleStep } from "./stepForm/CardsRadioGroupTripleStep";
import { CardsQuantitiesGroupStep } from "./stepForm/CardsQuantitiesGroupStep";
import { CardsQuantityGroupStep } from "./stepForm/CardsQuantityGroupStep";
import { CardsQuantityAndRadioGroupStep } from "./stepForm/CardsQuantityAndRadioGroupStep";
import { CardsRadioGroupCollectionStep } from "./stepForm/CardsRadioGroupCollectionStep";
import { ReviewPdf } from "./stepForm/ReviewPdf";
import { MultiInputStep } from "./stepForm/MultiInputStep";

import { m } from "../utils/i18n";
import {
  getWizardSteps,
  getDataFilteredStep,
  getSiteParams,
  resetNextStepsFormData,
  navigateNext,
  navigatePrev,
  fetchWizardConfiguration,
  fetchConfigurationProvince,
  fetchUserData,  
} from "../configuration/wizardConfiguration";

import "../styles.css";

const siteParams = getSiteParams();

let wizardConfiguration = {};

// const useStyles = makeStyles({
//   // logoImage: {
//   //   width: "100%",
//   //   maxWidth: "300px",
//   //   height: "100%",
//   //   maxHeight: "100px",
//   // },
//   // darkBand: {
//   //   backgroundImage: `url(${siteParams.dark_bar_src})`,
//   //   height: "180px",
//   //   // height: "15vh",
//   //   // background-size: auto 100%;
//   //   backgroundRepeat: "no-repeat",
//   //   backgroundPosition: "center",
//   //   // backgroundSize: "cover",
//   // },
//   // darkBandText: {
//   //   color: "#FFFFFF",
//   // },
// });

export const MultiStepForm = () => {
  const [loadingResource, setLoadingResource] = useState("CONFIGURATION");
  const [firstStepData, setFirstStepData] = React.useState(null);

  useEffect(() => {
    setLoadingResource("SETTINGS");
    const loadConfiguration = async () => {
      wizardConfiguration = await fetchWizardConfiguration();

      // setLoadingResource("DATA");
      // await fetchConfiguratorData();

      setLoadingResource("LOCALIZED MESSAGES");
      await fetchi18nMessages();
      setLoadingResource("USER SETTINGS");
      await fetchUserData();      
      setLoadingResource("GEOLOCALIZATION DATA");
      await fetchConfigurationProvince();

      const [steps, defaultData] = getWizardSteps(wizardConfiguration);
      // await getDataFilteredStep(defaultData, steps, steps[0],null, setFirstStepData);
      await getDataFilteredStep("", defaultData, steps, steps[0], setFirstStepData);

      console.log("loadConfiguration complete .. setReady");
      setLoadingResource(null);
    };

    loadConfiguration();
  }, []);

  console.log("check isReady");
  if (loadingResource !== null) {
    return (
      <div>
        <small>{`LOADING ${loadingResource} ...`}</small>
      </div>
    );
  } else {
    return <MultiStepFormContents {...firstStepData} />;
  }
};

export const MultiStepFormContents = (firstStepData) => {
  // const classes = useStyles();
  const [navAction, setNavAction] = React.useState("");
  const [loadingStepData, setLoadingStepData] = React.useState(false);
  const [steps, defaultData] = getWizardSteps(wizardConfiguration);

  const [formData, setFormValues, setForm] = useForm(defaultData);
  // const [showSummary, setShowSummary] = React.useState(false);
  // const [messageSnackbarOpen, setMessageSnackbarOpen] = React.useState(false);
  const [messageSnackbar, setMessageSnackbar] = React.useState(null);
  const [stepData, setStepData] = React.useState(firstStepData);
  // const [stepData, setStepData] = React.useState(null);

  const [errors, setErrors] = React.useState({});

  const { index, step, navigation } = useStep({
    steps,
    initialStep: 0,
  });


  if (loadingStepData) {
    (async () => {
      // await getDataFilteredStep(formData, steps, step, navigation, setStepData);


      setLoadingStepData(false);
      await getDataFilteredStep(navAction, formData, steps, step, setStepData);

      // setLoadingStepData(false);

      // var a = 0;
      // if (a == 1) {
      //   await getDataFilteredStep(formData, steps[4], setStepData);
      //   setLoadingStepData(false);
      // }

      console.log("loadingStepData false ");
    })();
  } else {
    if (stepData.id !== step.id){
      const gotoIdx =  stepData.id-1;
      navigation.go(gotoIdx)
    }

  }





  const updatedFormData = resetNextStepsFormData(steps, step, formData);

  if (!_.isEqual(updatedFormData, formData)) {
    setFormValues({ ...updatedFormData });
  }




  const props = {
    setNavAction,
    formData,
    setFormValues,
    setForm,
    navigation,
    stepData,
    steps,
    setLoadingStepData,
    errors,
    setErrors,
  };
  const summaryProps = { formData, steps };

  // const { title, subtitle } = stepData.params;
  const { title } = stepData.params;

  const lang = detectBrowserLanguage();

  let currentStepComponent = (
    <div>
      <h1>MSF</h1>
    </div>
  );

  // if (!loadingStepData) {
  switch (stepData.type) {
    case "Intro":
      currentStepComponent = <Intro {...props} />;
      break;
    case "DynamicGridStep":
      currentStepComponent = <DynamicGridStep {...props} />;
      break;
    case "RadioGroupStep":
      currentStepComponent = <RadioGroupStep {...props} />;
      break;
    case "CardsRadioGroupStep":
      currentStepComponent = <CardsRadioGroupStep {...props} />;
      break;
    case "CardsSelectGroupStep":
      currentStepComponent = <CardsSelectGroupStep {...props} />;
      break;
    case "SingleCardMultiRadioGroupStep":
      currentStepComponent = <SingleCardMultiRadioGroupStep {...props} />;
      break;
    case "CardsRadioGroupMulticolumnStep":
      currentStepComponent = <CardsRadioGroupStep {...props} />;
      break;
    case "CardsRadioGroupDoubleStep":
      currentStepComponent = <CardsRadioGroupDoubleStep {...props} />;
      break;
    case "CardsQuantityDoubleGroupStep":
      currentStepComponent = <CardsQuantityDoubleGroupStep {...props} />;
      break;
    case "CardsRadioGroupTripleStep":
      currentStepComponent = <CardsRadioGroupTripleStep {...props} />;
      break;
    case "CardsRadioGroupCollectionStep":
      currentStepComponent = <CardsRadioGroupCollectionStep {...props} />;
      break;
    case "CardsQuantitiesGroupStep":
      currentStepComponent = <CardsQuantitiesGroupStep {...props} />;
      break;
    case "CardsQuantityGroupStep":
      currentStepComponent = <CardsQuantityGroupStep {...props} />;
      break;
    case "CardsQuantityAndRadioGroupStep":
      currentStepComponent = <CardsQuantityAndRadioGroupStep {...props} />;
      break;
    case "ReviewPdf":
      currentStepComponent = <ReviewPdf {...props} />;
      break;
    case "MultiInputStep":
      currentStepComponent = <MultiInputStep {...props} />;
      break;
    default:
      console.log("Step undefined!");
      currentStepComponent = <div>Missing in MultiStepForm {step.type}</div>;
  }
  // }

  const {
    theme_palette_primary_main,
    theme_palette_secondary_main,
    theme_overrides_MuiRadio_root_color,
    theme_overrides_MuiRadio_colorSecondary_checkedcolor,
  } = siteParams;

  const theme = createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: theme_palette_primary_main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: theme_palette_secondary_main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },
    shape: {
      borderRadius: 16,
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.

      fontFamily: "Quicksand",
      fontSize: 12,
    },

    overrides: {
      MuiRadio: {
        root: {
          color: theme_overrides_MuiRadio_root_color,
        },
        colorSecondary: {
          "&$checked": {
            color: theme_overrides_MuiRadio_colorSecondary_checkedcolor,
          },
        },
      },
    },
  });

  // const theme = useTheme();
  const bigScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const bandHeight = bigScreen ? "25vh" : "25vh";
  const bandImage = siteParams.dark_bar_src
    .replace("LANGUAGE", lang === "it" ? "it" : "en")
    .replace("DEVICE", bigScreen ? "desktop" : "mobile");

  return (
    <ThemeProvider theme={theme}>
      <MessageSnackbar {...{ messageSnackbar, setMessageSnackbar }} />
      {/* <Container maxWidth="xl"> */}
      {/* <Grid container direction="row" justify="center" alignItems="stretch"> */}
      <Grid
        container
        // spacing={1}
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        {/* <!-- Double LOGO --> */}
        <Grid item xs align="center">
          <Box my={1}>
            <img
              className="logo"
              src={siteParams.logo_src}
              alt={siteParams.logo_alt}
            />
          </Box>
        </Grid>

        {/* <!-- Dark LOGO --> */}
        <Grid item xs align="center">
          <Box
            height={bandHeight}
            bgcolor={siteParams.band_background_color}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
          >
            <img
              className="darkBandImage"
              src={bandImage}
              alt={siteParams.logo_alt}
            />
          </Box>
          <Typography variant="h4" align="center" className="darkBandText">
            {/* {m("common.title")} */}
          </Typography>
        </Grid>

        {/* <!-- PROGRESS --> */}
        <Grid
          container
          item
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={10} sm={4}>
            {step.progress ? (
              <Box width="100%" justifyContent="center" my={2}>
                <ProgressWithValueLabel value={parseInt(step.progress)} />
              </Box>
            ) : (
              //   <Box width="25%" justifyContent="center" mt={2}>
              //   <ProgressWithValueLabel value={parseInt(step.progress)} />
              // </Box>

              <div></div>
            )}
          </Grid>
        </Grid>

        {/* <!-- SUBTITLE --> */}
        <Grid item align="center">
          <Box mt={1} p={1}>
            {/* <Box p="5vh"> */}
            <Typography variant="h4" align="center">
              <div className="titleWidthImage">
                <ReactMarkdown children={title} />
              </div>
            </Typography>
          </Box>
        </Grid>

        {/* <!-- MAIN STEP CONTENT  --> */}
        <Grid
          item
          container
          spacing={4}
          direction="row"
          justify="space-around"
          alignItems="center"
          className="blocksContainer"
        >
          <Box width="100%" mx={6}>
            {!loadingStepData ? currentStepComponent : <div></div>}
          </Box>
        </Grid>

        {/* <!-- SUMMARY  --> */}
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="summaryContainer"
        >
          <SummaryCard {...summaryProps} />

          {/* <Box my={7} mr={10} justifyContent="center" align="center">
            <SummaryCard {...summaryProps} />
          </Box> */}
        </Grid>

        {/* <Grid item align="center">
          {step.progress ? (
            <Box width="25%" justifyContent="center" mt={2}>
              <ProgressWithValueLabel value={parseInt(step.progress)} />
            </Box>
          ) : (
            <div></div>
          )}
        </Grid> */}
      </Grid>
      {/* </Grid> */}
      {/* </Container> */}

      {/* <!-- PREVIOUS --> */}
      {["Intro", "ReviewPdf"].includes(step.type) ||
      _.findIndex(steps, step) === 0 ? (
        <div></div>
      ) : bigScreen ? (
        <div className="previousText">
          <Button
            variant="contained"
            color="primary"
            style={{
              color: "white",
              "border-top-left-radius": "0px",
              "border-bottom-left-radius": "0px",
            }}
            onClick={async () => {
              await navigatePrev(
                setNavAction,
                navigation,
                step,
                formData,
                setLoadingStepData
              );
              // setLoadingStepData(true);
              // console.log("loadingStepData true ");
            }}
            // onClick={() => {
            //   navigation.previous();
            //   setLoadingStepData(true);
            //   console.log("loadingStepData true ");
            // }}
          >
            <NavigateBeforeIcon fontSize="large" />
            <Box style={{ display: { xl: "none", md: "block" } }}>
              {m("common.previous")}
            </Box>
          </Button>
        </div>
      ) : (
        <Link
          href="#"
          onClick={async () => {
            await navigatePrev(
              setNavAction,
              navigation,
              step,
              formData,
              setLoadingStepData
            );
            // setLoadingStepData(true);
            // console.log("loadingStepData true ");
          }}


          // onClick={() => {
          //   navigation.previous();
          //   setLoadingStepData(true);
          //   console.log("loadingStepData true ");
          // }}
        >
          <div
            className="previous"
            style={{ backgroundColor: `${theme_palette_primary_main}` }}
          >
            <NavigateBeforeIcon className="nav_icons" />
          </div>
        </Link>
      )}
      {/* <!-- NEXT --> */}
      {/* {["ReviewPdf"].includes(step.type) ? ( */}
      {["Intro", "ReviewPdf"].includes(step.type) ||
      _.findIndex(steps, step) === steps.length - 1 ? (
        <div></div>
      ) : bigScreen ? (
        <div className="nextText">
          <Button
            variant="contained"
            color="primary"
            style={{
              color: "white",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              paddingRight: "6px",
            }}
            // style={{
            //   color: "white",
            //   "border-top-right-radius": "0px",
            //   "border-bottom-right-radius": "0px",
            //   "padding-right": "6px",
            // }}
            // className="nextButton"   NON FUNZIONA UN CAZZO

            onClick={async () => {
              await navigateNext(
                setNavAction,
                navigation,
                index,
                step,
                steps,
                formData,
                setMessageSnackbar,
                errors,
                setErrors,
                stepData,
                setStepData,
                setLoadingStepData
              );
              // setLoadingStepData(true);
              console.log("loadingStepData true ");
            }}
          >
            {m("common.next")}
            <NavigateNextIcon fontSize="large" />
          </Button>
        </div>
      ) : (
        <Link
          href="#"
          onClick={async () => {
            await navigateNext(
              setNavAction,
              navigation,
              index,
              step,
              steps,
              formData,
              setMessageSnackbar,
              errors,
              setErrors,
              stepData,
              setStepData,
              setLoadingStepData
            );
            // setLoadingStepData(true);
            // console.log("loadingStepData true ");
          }}
        >
          <div
            className="next"
            style={{ backgroundColor: `${theme_palette_primary_main}` }}
          >
            <NavigateNextIcon className="nav_icons" />
          </div>
        </Link>
      )}
    </ThemeProvider>
  );
};
