import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

import InfoIcon from "@material-ui/icons/Info";

import ReactMarkdown from "react-markdown";
// DEMO https://remarkjs.github.io/react-markdown/
// ![test](https://drive.google.com/uc?export=view&id=1Cp5EVAUAHuenBKcfSz37uqH-vgsuS84x)

import { getSiteParams } from "../../configuration/wizardConfiguration";
const { img_base_url, theme_palette_primary_main } = getSiteParams();

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: 400,
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
  },
  divider: {
    // Theme Color, or use css color in quote
    // background: theme.palette.divider,
    background: theme_palette_primary_main,
  },
}));

export default function SimpleModal({ title, content }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // add url prefix to images
  // es. "... ![Immagine Misure](FVD300002_popup.png)..."
  var text = "";
  let array = content.split(".png)");
  array.forEach(function (item, index) {
    var str = item;
    if (item.lastIndexOf("](") > 0) {
      str =
        item.substr(0, item.lastIndexOf("](") + 2) +
        img_base_url +
        item.substr(item.lastIndexOf("](") + 2);
      str += ".png)";
    }
    text += str;
  });

  // const body = (
  //   <div style={modalStyle} className={classes.paper}>
  //     <h2 id="simple-modal-title">Text in a modal</h2>
  //     <p id="simple-modal-description">
  //       Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
  //     </p>
  //     {/* <SimpleModal /> */}
  //   </div>
  // );

  // const content =
  //   "sss\n* Implements [GitHub Flavored Markdown](https://github.github.com/gfm/)" +
  //   '\n* Renders actual, "native" React DOM elements';
  // * Allows you to escape or skip HTML (try toggling the checkboxes above)
  // * If you escape or skip the HTML, no `dangerouslySetInnerHTML` is used! Yay!";
  return (
    <div id="modal">
      <IconButton
        color="primary"
        component="span"
        size="small"
        onClick={handleOpen}
      >
        <InfoIcon />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box style={modalStyle} className={classes.paper}>
          <Typography variant="h6" align="center">
            {title}
          </Typography>
          <Divider classes={{ root: classes.divider }} />
          <Typography variant="body1" align="left" component="span">
            <ReactMarkdown children={text} />
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
