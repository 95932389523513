// import React from "react";
import React from "react";
import _ from "lodash";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

// import IconButton from "@material-ui/core/IconButton";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";

import { m, detectBrowserLanguage } from "../utils/i18n";


// https://github.com/hibiken/react-places-autocomplete
// https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest.bounds

// import PlacesAutocomplete, {
//   geocodeByAddress,
//   geocodeByPlaceId,
//   getLatLng,
// } from "react-places-autocomplete";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(5),
  },
}));

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 280,
//   },
// }));

const loadGoogleMapsScript = (loaded) => {
  const lang = detectBrowserLanguage();
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyD7pz8jrpgFTlQU6wgTISmzvofF1ZYr6C0&libraries=places&language=" +
          (lang === "it" ? "it" : "en"),

        document.querySelector("head"),
        "google-maps"
      );
    }

    return true;
  }
  return false;
};

const FullAddress = ({
  formData,
  setForm,
  setFormValues,
  navigation,
  stepData,
  errors,
  setErrors,
  addressValue,
  setAddressValue,
}) => {
  const widget = stepData.params.addressWidget;
  // const widget = {
  //   fullAddressLabel: m("common.input.fullAddress"),
  //   fullAddressName: "fullAddress",
  //   fullAddressValue: "fullAddress",
  //   provinceLabel: m("common.input.province"),
  //   provinceName: "province",
  //   provinceValue: "province",
  //   provinceItem: "provinceItem",
  //   options: [
  //     { label: "Padova", value: "PD" },
  //     { label: "Padova2", value: "PD2" },
  //     { label: "Padova3", value: "PD3" },
  //     { label: "Padova4", value: "PD4" },
  //     { label: "Padova5", value: "PD5" },
  //     { label: "Treviso", value: "TV" },
  //     { label: "Padova7", value: "PD7" },
  //     { label: "Padova8", value: "PD8" },
  //     { label: "Padova9", value: "PD9" },
  //   ],
  // };

  const classes = useStyles();

  // const addressValue = formData[widget.fullAddressValue]
  // const [value, setValue] = React.useState(addressValue);

  // const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (loadGoogleMapsScript(loaded)) {
    loaded.current = true;
  }

  const handleFocus = (event) => {
    let err = { ...errors };
    err[event.target.name] = "";
    setErrors(err);
  };


  // let nv = {description : "Via Gaspare Stampa, Caltavuturo, PA, Italy"};
  // newValue = nv;

  // FulllAddress
  const handleChange = (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setAddressValue(newValue);

    const placeId = newValue?.place_id;

    // value deleted
    if (placeId === undefined) {
      let updatedFormData = { ...formData };

      updatedFormData[widget.fullAddressValue] = newValue
        ? newValue.description
        : "";

      setFormValues({ ...updatedFormData });
      return;
    }
    // window.google.maps.places.AutocompleteService();
    // maps/api/place/details
    // const service = new google.maps.places.PlacesService(map);

    const request = {
      placeId: placeId,
      // fields: ["name", "formatted_address", "place_id", "geometry"],
      fields: ["address_components"],
      // https://developers.google.com/maps/billing/gmp-billing#places-product  gratuito per info base
    };

    // const request = {
    //   placeId: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    //   fields: ["name", "formatted_address", "place_id", "geometry"],
    // };

    let map = new window.google.maps.Map(document.createElement("div"));
    const service = new window.google.maps.places.PlacesService(map);

    // https://developers.google.com/maps/documentation/javascript/reference/places-service
    service.getDetails(request, (place, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place &&
        place.address_components
      ) {
        let updatedFormData = { ...formData };

        updatedFormData[widget.fullAddressValue] = newValue
          ? newValue.description
          : "";

        // const province = newValue ? _.nth(newValue?.terms, -2).value : "";
        const ctry = _.find(place.address_components, function (obj) {
          return obj.types[0] === "country";
          // setProvince(pr ? pr.short_name : "");
        });

        // const postalCodeObj = _.find(place.address_components, function (obj) {
        //   return obj.types[0] === "postal_code";
        // });

        // updatedFormData[widget.postalCodeValue] = postalCodeObj.short_name
        //   ? postalCodeObj.short_name
        //   : "";

        // console.log(postalCodeObj.short_name);

        let postalCode = "";
        if (ctry && ctry.short_name === "IT") {
          const pc = _.find(place.address_components, function (obj) {
            return obj.types[0] === "postal_code";
          });
          postalCode = pc && pc.short_name ? pc.short_name : "";
          console.log("** CAP:    " + postalCode);
        }

        updatedFormData[widget.postalCodeValue] = postalCode ? postalCode : "";

        let province = "";
        if (ctry && ctry.short_name === "IT") {
          const pr = _.find(place.address_components, function (obj) {
            return obj.types[0] === "administrative_area_level_2";
            // setProvince(pr ? pr.short_name : "");
          });
          province = pr.short_name;
          console.log("** PROVINCIA:    " + province);
        }

        updatedFormData[widget.provinceValue] = province;
        const provinceItem = _.find(widget.options, { value: province });

        updatedFormData[widget.provinceItem] =
          provinceItem !== undefined ? provinceItem : null;

        setFormValues({ ...updatedFormData });

        // const marker = new google.maps.Marker({
        //   map,
        //   position: place.geometry.location,
        // });
        // google.maps.event.addListener(marker, "click", function () {
        //   infowindow.setContent(
        //     "<div><strong>" +
        //       place.name +
        //       "</strong><br>" +
        //       "Place ID: " +
        //       place.place_id +
        //       "<br>" +
        //       place.formatted_address +
        //       "</div>"
        //   );
        //   // @ts-ignore
        //   infowindow.open(map, this);
      }
    });
  };




  //https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        const lang = detectBrowserLanguage();
        const country = lang === "it" ? ["it"] : [];
        let autocompletionRequest = {
          componentRestrictions: {
            country: country,
          },
          // types: ["(cities)"],
          types: ["address"],
        };
        autocompletionRequest.input = request.input;

        autocompleteService.current.getPlacePredictions(
          autocompletionRequest,
          callback
        );
      }, 200),
    []
  );

  React.useEffect(() => {
    // default value #remember_me
    const def = typeof formData[widget.fullAddressValue] != "undefined" ? formData[widget.fullAddressValue] : null;    

    // create fake object for avoinding exception reading followng attributes 
    let defaultAddressValue = 
    {
        description:def, 
        structured_formatting:
          {
              main_text_matched_substrings:[], 
              main_text:"" 
          }
    }
    setAddressValue(defaultAddressValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //Runs only on the first render -> []


  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(addressValue ? [addressValue] : []);
      return undefined;
    }

    // let prov = "r";
    // fetch({ input: prov }, (results) => {
    //   console.log("prov \t\t" + results[0].terms[2]);
    // });

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (addressValue) {
          newOptions = [addressValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [addressValue, inputValue, fetch]);


  // una volta negato il permesso bisogna riattivarlo da settings (es. deliveroo: dopo il rifiuto non funziona più)
  const getLocation = () => {
    if (!navigator.geolocation) {
      alert(m("userdata.geolocation_not_supported"));
    } else {
      // setStatus('Locating...');
      navigator.geolocation.getCurrentPosition((position) => {
        const latlng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        const request = {location: latlng};
        const service = new window.google.maps.Geocoder();
        service.geocode(request, (result, status) => {
          if (
            status === window.google.maps.GeocoderStatus.OK &&
            result &&
            result[0].address_components
          ) {
            const item = result[0];
            const newElement = {
              place_id: item.place_id,
              description: item.formatted_address
            }
            handleChange(null, newElement);
            console.log("ok");
          }
        });

      }, () => {
        
        alert(m("userdata.location_not_available"));
      });
    }
  }

  return (
      <Autocomplete
        id={widget.fullAddressName}
        // style={{ width: 300 }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={addressValue}
        // value={formData[widget.fullAddressValue]}
        onChange={(event, newValue) => {
          handleChange(event, newValue);
        }}
        // onChange={(event, newValue) => {
        //   setOptions(newValue ? [newValue, ...options] : options);
        //   setValue(newValue);
        // }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <Box sx={{ alignContent: 'center' }}>
            <TextField
              {...params}
              label={widget.fullAddressLabel}
              name={widget.fullAddressName}
              margin="normal"
              required
              variant="outlined"
              error={Boolean(errors[widget.fullAddressName])}
              helperText={errors[widget.fullAddressName]}
              onFocus={handleFocus}
              style = {{width: "100%"}}
              // fullWidth
            />
            <Box align="center" my={1}>
            <Button
                variant="contained"
                color="secondary"
                onClick={getLocation}
                endIcon={<MyLocationIcon />}
            >
              {m("userdata.location_button")}
            </Button>
            </Box>  
          </Box>

          // <Box sx={{ alignContent: 'center' }}>
          //   <TextField
          //     {...params}
          //     label={widget.fullAddressLabel}
          //     name={widget.fullAddressName}
          //     margin="normal"
          //     required
          //     variant="outlined"
          //     error={Boolean(errors[widget.fullAddressName])}
          //     helperText={errors[widget.fullAddressName]}
          //     onFocus={handleFocus}
          //     style = {{width: "89%"}}
          //     // fullWidth
          //   />
          //   <IconButton  
          //     style = {{marginTop: "15px", marginLeft: "0px", paddingTop:"12px", paddingLeft:"3px", paddingRight:"0px"}}
          //     color="primary" aria-label="my location" component="label" onClick={getLocation}>
          //     <MyLocationIcon fontSize="large"/>
          //   </IconButton>          
          // </Box>
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />  
      


  );
};

const Province = ({
  formData,
  setForm,
  setFormValues,
  navigation,
  stepData,
  errors,
  setErrors,
  addressValue,
  setAddressValue,
}) => {
  const widget = stepData.params.addressWidget;

  const { options } = widget;

  // Province
  const handleChange = (newValue) => {
    const lang = detectBrowserLanguage();

    let updatedFormData = { ...formData };

    // se cambia provincia e locale it resetta indirizzo
    const currentProvince = updatedFormData[widget.provinceValue];
    if (newValue && newValue.value !== currentProvince && lang === "it") {
      console.log("**** RESET ADDRESS  ***");
      updatedFormData[widget.fullAddressValue] = "";
      setAddressValue(null);
    }

    updatedFormData[widget.provinceValue] = newValue
      ? newValue.value !== undefined
        ? newValue.value
        : _.values(newValue).join("")
      : "";
    updatedFormData[widget.provinceItem] = newValue
      ? newValue.value !== undefined
        ? newValue
        : {
            label: _.values(newValue).join(""),
            value: _.values(newValue).join(""),
          }
      : "";

    setFormValues({ ...updatedFormData });

    // setValuexx(newValue);
  };

  const handleFocus = (event) => {
    let err = { ...errors };
    err[event.target.name] = "";
    setErrors(err);
  };

  const optionSelected = (option, value) => {
    return _.isEqual(option, value);
    // return option.value === value.value;
  };


  // default value #remember_me  
  // const def = typeof formData[widget.provinceName] != "undefined" ? _.find(options, { value: formData[widget.provinceName] }) : null;

  return (
    <Autocomplete
      id={widget.provinceName}
      freeSolo={detectBrowserLanguage() !== "it"}
      options={options}
      getOptionLabel={(option) => option.label}
      value={formData[widget.provinceItem]}
      onChange={(event, newValue) => {
        handleChange({ ...newValue });
      }}
      // defaultValue={selectedOption}
      getOptionSelected={(option, value) => optionSelected(option, value)}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          label={widget.provinceLabel}
          name={widget.provinceName}
          required
          variant="outlined"
          error={Boolean(errors[widget.provinceName])}
          helperText={errors[widget.provinceName]}
          onFocus={handleFocus}
        />
      )}
    />
  );
};

const PostalCode = ({
  formData,
  setForm,
  setFormValues,
  navigation,
  stepData,
  errors,
  setErrors,
  addressValue,
  setAddressValue,
}) => {
  const widget = stepData.params.addressWidget;
  const { postalCode } = formData;

  // PostalCode
  const handleChange = (event) => {
    let updatedFormData = { ...formData };
    const newValue = event.target.value.trim();

    // se cambio CAP resetta Provincia (per ora NO)
    // const currentPostalCode = updatedFormData[widget.postalCodeValue];
    // if (newValue !== currentPostalCode) {
    //   console.log("**** RESET PROVINCE  ***");
    //   updatedFormData[widget.provinceValue] = "";
    //   updatedFormData[widget.provinceItem] = null;
    // }

    updatedFormData[widget.postalCodeValue] = newValue;
    setFormValues({ ...updatedFormData });
  };

  const handleFocus = (event) => {
    let err = { ...errors };
    err[event.target.name] = "";
    setErrors(err);
  };

  return (
    <TextField
      label={widget.postalCodeLabel}
      name={widget.postalCodeValue}
      value={postalCode}
      onChange={handleChange}
      onFocus={handleFocus}
      margin="normal"
      variant="outlined"
      autoComplete="off"
      required
      error={Boolean(errors?.postalCode)}
      helperText={errors?.postalCode}
      fullWidth
    />

    // <Autocomplete
    //   id={widget.provinceName}
    //   freeSolo={detectBrowserLanguage() !== "it"}
    //   options={options}
    //   getOptionLabel={(option) => option.label}
    //   value={formData[widget.provinceItem]}
    //   onChange={(event, newValue) => {
    //     handleChange({ ...newValue });
    //   }}
    //   // defaultValue={selectedOption}
    //   getOptionSelected={(option, value) => optionSelected(option, value)}
    //   style={{ width: "100%" }}
    //   renderInput={(params) => (
    //     <TextField
    //       {...params}
    //       margin="normal"
    //       label={widget.provinceLabel}
    //       name={widget.provinceName}
    //       required
    //       variant="outlined"
    //       error={Boolean(errors[widget.provinceName])}
    //       helperText={errors[widget.provinceName]}
    //       onFocus={handleFocus}
    //     />
    //   )}
    // />
  );
};

export const Address = (props) => {
  const [addressValue, setAddressValue] = React.useState(null);

  //   const thirdObject = {
  //     ...firstObject,
  //     ...secondObject
  //  }
  //  <MessageSnackbar {...{ messageSnackbarOpen, setMessageSnackbarOpen }} />

  return (
    <div>
      <FullAddress {...{ ...props, ...{ addressValue, setAddressValue } }} />
      <PostalCode {...{ ...props, ...{ addressValue, setAddressValue } }} />
      <Province {...{ ...props, ...{ addressValue, setAddressValue } }} />
    </div>
  );
};

// return (
//   <div>
//     <FullAddress {...{ props, ... {addressValue, setAddressValue}}} />

//     <Province {...props, addressValue, setAddressValue} />
//   </div>
// );
// };

// ****************************************************************************************************************
// ****************************************************************************************************************
// ****************************************************************************************************************
//   NON USATI
// ****************************************************************************************************************
// ****************************************************************************************************************
// ****************************************************************************************************************
/*

const PostalCode = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (loadGoogleMapsScript(loaded)) {
    loaded.current = true;
  }

  //https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        const lang = detectBrowserLanguage();
        const country = lang === "it" ? ["it"] : [];
        let autocompletionRequest = {
          componentRestrictions: {
            country: country,
          },
          types: ["(regions)"],
        };
        autocompletionRequest.input = request.input;

        autocompleteService.current.getPlacePredictions(
          autocompletionRequest,
          callback
        );
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleChange = (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
  };

  return (
    <Autocomplete
      id="postal-code"
      // style={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        handleChange(event, newValue);
      }}
      //   setOptions(newValue ? [newValue, ...options] : options);
      //   setValue(newValue);
      // }}
      onInputChange={(event, newInputValue) => {
        // const re = /^[0-9\b]+$/;
        // if (newInputValue === "" || re.test(newInputValue)) {
        //   setInputValue(newInputValue.replace(/\D/g,''));
        // }

        const numericNewInputValue = newInputValue.replace(/\D/g, "");
        setInputValue(numericNewInputValue);
        // event.target.value = numericNewInputValue;
        // setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          // onChange={(event, newValue) => {
          //   event.target.value = event.target.value.replace(/\D/g, "");
          //   console.log(event);
          //   console.log("ciccio");
          // }}
          {...params}
          label={m("common.input.postlCode")}
          name="postlCode"
          margin="normal"
          variant="outlined"
          required
          fullWidth
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

const ExFullAddress = (props) => {
  const [address, setAddress] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    setAddress(value);
    // const a = getLatLng(result[0]);

    const pc = _.find(result[0].address_components, function (obj) {
      return obj.types[0] === "postal_code";
    });
    setPostalCode(pc ? pc.long_name : "");

    const pr = _.find(result[0].address_components, function (obj) {
      return obj.types[0] === "administrative_area_level_2";
    });
    setProvince(pr ? pr.short_name : "");
  };

  // const searchOptions = {
  //   componentRestrictions: {
  //     country: ["it"],
  //   },
  //   types: ["address"],
  // };

  const searchOptions = {
    types: ["address"],
  };

  console.log(searchOptions);

  return (
    <div key="address">
      <p>Full Address: {province} </p>

      <PlacesAutocomplete
        value={address}
        searchOptions={searchOptions}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div key={suggestions.description}>
            <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "red", cursor: "pointer" }
                  : { backgroundColor: "yellow", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

const ExPostalCode = (props) => {
  const [address, setAddress] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    setAddress(value);
    // const a = getLatLng(result[0]);

    const pc = _.find(result[0].address_components, function (obj) {
      return obj.types[0] === "postal_code";
    });
    setPostalCode(pc ? pc.long_name : "");

    const pr = _.find(result[0].address_components, function (obj) {
      return obj.types[0] === "administrative_area_level_2";
    });
    setProvince(pr ? pr.short_name : "");
  };

  const searchOptions = {
    types: ["(cities)"],
  };

  console.log(searchOptions);

  return (
    <div key="address">
      <p>provincia: {province} </p>
      <p>CAP: {postalCode} </p>

      <PlacesAutocomplete
        value={address}
        searchOptions={{
          componentRestrictions: {
            country: ["it"],
          },
          types: ["(regions)"],
        }}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div key={suggestions.description}>
            <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "red", cursor: "pointer" }
                  : { backgroundColor: "yellow", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

*/

/*
(8) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
0:{long_name: '72', short_name: '72', types: Array(1)}
1:{long_name: 'Via Gaspara Stampa', short_name: 'Via Gaspara Stampa', types: Array(1)}
2:{long_name: 'Noventana', short_name: 'Noventana', types: Array(2)}
  long_name:'Noventana'
  short_name:'Noventana'
  types:(2) ['locality', 'political']
3:{long_name: 'Noventa padovana', short_name: 'Noventa padovana', types: Array(2)}
  long_name:'Noventa padovana'
  short_name:'Noventa padovana'
  types:(2) ['administrative_area_level_3', 'political']
4:{long_name: 'Provincia di Padova', short_name: 'PD', types: Array(2)}
  long_name:'Provincia di Padova'
  short_name:'PD'
  types:(2) ['administrative_area_level_2', 'political']
5:{long_name: 'Veneto', short_name: 'Veneto', types: Array(2)}
6:{long_name: 'Italy', short_name: 'IT', types: Array(2)}
7:{long_name: '35027', short_name: '35027', types: Array(1)}
  long_name:'35027'
  short_name:'35027'
  types:(1) ['postal_code']


*/
