import React from "react";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import { m } from "../../utils/i18n";

export const Intro = ({ formData, setForm, navigation, stepData, setLoadingStepData }) => {
  const { buttonLabel } = stepData.params;
  return (
    // <Container maxWidth="sm">
    <Box textAlign="center">
      <Button
        fontSize="large"
        size="large"
        variant="contained"
        color="primary"
        style={{ marginTop: "1rem" }}
        onClick={() => {
          navigation.next();
          setLoadingStepData(true);
          console.log("loadingStepData true ");          
        }}
      >
        {m(buttonLabel)}
      </Button>
    </Box>
    // </Container>
  );
};
