/* eslint no-eval: 0 */

export const evalExpression = (exp, formData) => {
  // visibleIf '{{cronotermostato}}=discovery-and-mini, cronotermostatoValue=discovery-and-probe '
  // eval("formData['cronotermostato']==='discovery-and-mini' || formData['cronotermostato']==='discovery-and-mini'")
  // eval("formData['cronotermostato']==='discovery-and-mini' || formData['cronotermostato']==='all-discovery'")
  // repeat '1'   '{{zone}}' '{{zone}}-1'

  const parsedExpression = exp
    // .replaceAll(",", " ||")
    // .replaceAll("=", "===")
    // .replaceAll("{{", "formData['")
    // .replaceAll("}}", "']");
    .replace(/,/g, " ||")
    .replace(/=/g, "===")
    .replace(/\{\{/g, "formData['")
    .replace(/\}\}/g, "']");

  return eval(parsedExpression);
};
