import React from "react";
import _ from "lodash";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import SimpleModal from "./SimpleModal";
import { getSiteParams } from "../../configuration/wizardConfiguration";

// import FormLabel from "@material-ui/core/FormLabel";

const { img_base_url, theme_palette_primary_main } = getSiteParams();

const useStyles = makeStyles((theme) => ({
  divider: {
    // Theme Color, or use css color in quote
    // background: theme.palette.divider,
    background: theme_palette_primary_main,
  },
  blocksContainer: {
    paddingRight: "50px",
    paddingLeft: "60px",
  },
}));

export const CardsRadioGroupDoubleStep = ({
  formData,
  setForm,
  navigation,
  stepData,
}) => {

  const { radioGroup } = stepData.params;
  const { options, groupLeft, groupRight } = radioGroup;
  const classes = useStyles();

  return (
    <FormControl component="fieldset" fullWidth={true}>
      {/* <FormLabel component="legend">Brand</FormLabel> */}
      <RadioGroup
        aria-label={radioGroup.label}
        name={radioGroup.name}
        value={formData[radioGroup.value]}
        onChange={setForm}
      >
        <Grid
          item
          container
          xs={12}
          spacing={2}
          alignContent="center"
          justify="space-around"
          className={classes.blocksContainer}
        >
          <Grid item container xs={12} sm={6} direction="column" spacing={0}>
            <Grid
              item
              container
              direction="row"
              spacing={1}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="body1" align="left">
                  {groupLeft.text}
                </Typography>
              </Grid>
              <Grid item>
                <img
                  src={`${img_base_url}${groupLeft.img_src}`}
                  alt={groupLeft.text}
                  width="40"
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider classes={{ root: classes.divider }} />
            </Grid>
            {_.filter(options, { group: groupLeft.value }).map(
              (card, card_index) => {
                const modal =
                  card.infoText && card.infoText !== "" ? (
                    <SimpleModal
                      {...{ title: card.label, content: card.infoText }}
                    />
                  ) : (
                    ""
                  );

                return (
                  <Grid
                    key={card.value}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <img
                        src={`${img_base_url}${card.img_src}`}
                        alt={card.img_alt}
                        className="imgHorizontalTile"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value={card.value}
                        control={<Radio />}
                        label={card.label}
                      />
                      <Box>{modal}</Box>
                    </Grid>
                  </Grid>
                );
              }
            )}
          </Grid>

          <Grid item container xs={12} sm={6} direction="column">
            <Grid
              item
              container
              direction="row"
              spacing={1}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="body1" align="left">
                  {groupRight.text}
                </Typography>
              </Grid>
              <Grid item>
                <img
                  src={`${img_base_url}${groupRight.img_src}`}
                  alt={groupRight.text}
                  width="40"
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider classes={{ root: classes.divider }} />
            </Grid>
            <Grid item container direction="column">
              {_.filter(options, { group: groupRight.value }).map(
                (card, card_index) => {
                  const modal =
                    card.infoText && card.infoText !== "" ? (
                      <SimpleModal
                        {...{ title: card.label, content: card.infoText }}
                      />
                    ) : (
                      ""
                    );

                  return (
                    <Grid
                      key={card.value}
                      item
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <img
                          src={`${img_base_url}${card.img_src}`}
                          alt={card.img_alt}
                          className="imgHorizontalTile"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value={card.value}
                          control={<Radio />}
                          label={card.label}
                        />
                        <Box>{modal}</Box>
                      </Grid>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid
            container
            spacing={3}
            alignContent="center"
            justify="space-around"
          >
            {options.map((card, card_index) => {
              const modal =
                card.infoText && card.infoText !== "" ? <SimpleModal /> : "";

              return (
                <div key={card_index}>
                  <img
                    src={`${img_base_url}${card.img_src}`}
                    alt={card.img_alt}
                  />
                  <div>{card.description}</div>
                  <FormControlLabel
                    key={card.value}
                    value={card.value}
                    control={<Radio />}
                    label={card.label}
                  />
                  <div>{modal}</div>
                </div>
              );
            })}
          </Grid> */}
      </RadioGroup>
    </FormControl>
  );
};
