import React from "react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import SimpleModal from "./SimpleModal";
import { makeStyles } from "@material-ui/core/styles";
import { getSiteParams } from "../../configuration/wizardConfiguration";
import ReactMarkdown from "react-markdown";

// import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles({
  imgDescription: {
    marginBottom: "40px",
    marginTop: "10px",
  },
});

const Quantity = ({ formData, setFormValues, setForm, stepData }) => {
  const { radioGroup } = stepData.params;
  // const { cards } = radioGroup;

  const updateQuantity = (updatedQ) => {
    let updatedFormData = { ...formData };

    if (isNaN(updatedQ) || updatedQ <= 0) {
      updatedFormData[radioGroup.quantityValue] = 0;
      updatedFormData[`${radioGroup.name}__code`] = "";
      updatedFormData[`${radioGroup.name}__price`] = "";
      updatedFormData[`${radioGroup.name}__description`] = "";
    } else {
      updatedFormData[radioGroup.quantityValue] = updatedQ;
      updatedFormData[`${radioGroup.name}__code`] = radioGroup.code;
      updatedFormData[`${radioGroup.name}__price`] = radioGroup.price;
      updatedFormData[`${radioGroup.name}__description`] =
        radioGroup.cards[0].description;
    }
    setFormValues({ ...updatedFormData });
  };

  const deltaQuantity = (delta) => {
    let updatedQ = formData[radioGroup.quantityValue] + delta;
    updateQuantity(updatedQ);
  };

  const setQuantity = (e) => {
    updateQuantity(parseInt(e.target.value));
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          deltaQuantity(-1);
        }}
      >
        <RemoveIcon />
      </IconButton>
      <TextField
        label={radioGroup.quantityLabel}
        name={radioGroup.quantityName}
        value={formData[radioGroup.quantityValue]}
        onChange={setQuantity}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        type="text"
        style={{ width: 70 }}
        inputProps={{
          min: 0,
          max: 100,
          style: {
            textAlign: "center",
            fontSize: "20px",
          },
        }}

        // inputProps={{ size: "3" }}
      />
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          deltaQuantity(+1);
        }}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export const CardsQuantityAndRadioGroupStep = (props) => {
  const classes = useStyles();

  const { formData, stepData, setFormValues } = props;
  const { radioGroup, optionGroup } = stepData.params;
  const { cards } = radioGroup;
  const { img_base_url } = getSiteParams();
  const { optionCards } = optionGroup;

  const handleOptionChange = (
    optionGroup,
    event,
    formData,
    stepData,
    setFormValues
  ) => {
    const { name, optionCards } = optionGroup;
    const { value } = event.target;
    let updatedFormData = { ...formData };

    // toggle
    const currentValue = updatedFormData[name];

    updatedFormData[name] =
      currentValue === event.target.value ? "" : event.target.value;

    // se c'è codice item lo mettiamo in formData
    const opt = _.find(optionCards, { value: value });
    if (_.has(opt, "code") && !_.isEmpty(opt.code)) {
      updatedFormData[`${name}___code`] = opt.code;
    }
    setFormValues({ ...updatedFormData });
  };

  return (
    <FormControl component="fieldset" fullWidth={true}>
      {/* <FormLabel component="legend">Brand</FormLabel> */}

      {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}> */}

      <Grid
        item
        container
        spacing={1}
        alignContent="center"
        alignItems="flex-start"
        justify="space-around"
      >
        <Grid item xs={12} sm={6}>
          <Grid
            item
            container
            spacing={1}
            alignContent="center"
            alignItems="flex-start"
            justify="space-around"
          >
            {/* <RadioGroup
              aria-label={radioGroup.label}
              name={radioGroup.name}
              value={formData[radioGroup.value]}
              onChange={setForm}
            > */}
            {cards.map((card, card_index) => {
              const modal =
                card.infoText && card.infoText !== "" ? (
                  <SimpleModal
                    {...{ title: card.description, content: card.infoText }}
                  />
                ) : (
                  ""
                );

              return (
                // <Grid
                //   item
                //   container
                //   xs={12}
                //   sm={6}
                //   md={4}
                //   lg={3}
                //   direction="column"
                //   spacing={1}
                //   key={card_index}
                // >
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  xs={8}
                  key={card_index}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    spacing={1}
                    justify="center"
                    alignItems="center"
                    className={classes.imgDescription}
                  >
                    <Grid item>{/* bilanca la i */}</Grid>
                    <Grid item>
                      <Typography variant="h5" align="center">
                        {card.description}
                      </Typography>
                    </Grid>
                    <Grid item>{modal}</Grid>
                  </Grid>

                  <Grid item xs sm={10} align="center">
                    {/* mettendo xs={10} scazza */}
                    <img
                      src={`${img_base_url}${card.img_src}`}
                      alt={card.img_alt}
                      className="imgTile"
                    />
                  </Grid>

                  <Grid item>
                    <Quantity {...props} />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {/* </RadioGroup> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <RadioGroup
            aria-label={optionGroup.label}
            name={optionGroup.name}
            value={formData[optionGroup.value]}
            // onChange={setForm}
            onClick={(e) => {
              handleOptionChange(
                optionGroup,
                e,
                formData,
                stepData,
                setFormValues
              );
            }}
          >
            {/* Riga items e riga nb */}
            <Grid
              item
              container
              direction="column"
              justify="space-around"
              alignItems="stretch"
            >
              {optionCards.length === 0 ? (
                <Grid
                  item
                  container
                  spacing={1}
                  alignContent="center"
                  alignItems="flex-start"
                  justify="space-around"
                >
                  <Box
                    display="flex"
                    width="50%"
                    justifyContent="center"
                    m={2}
                    px={2}
                  >
                    <Typography variant="h6" align="center">
                      <ReactMarkdown
                        children={stepData.params.messageUnavailable}
                      />
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  spacing={1}
                  alignContent="center"
                  alignItems="flex-start"
                  justify="space-around"
                >
                  {optionCards.map((card, card_index) => {
                    // const props = { title: card.label, content: card.infoText };
                    const modal =
                      card.infoText && card.infoText !== "" ? (
                        <SimpleModal
                          {...{ title: card.label, content: card.infoText }}
                        />
                      ) : (
                        ""
                      );

                    const nb =
                      card.nbText && card.nbText !== "" ? (
                        <Box
                          display="flex"
                          color="red"
                          width="100%"
                          justifyContent="center"
                          pt={3}
                        >
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 600 }}
                            align="center"
                          >
                            {card.nbText}
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      );

                    return (
                      <Grid
                        item
                        container
                        direction="column"
                        alignItems="center"
                        xs={8}
                        key={card_index}
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          spacing={1}
                          justify="center"
                          alignItems="center"
                          className={classes.imgDescription}
                        >
                          <Grid item>{/* bilanca la i */}</Grid>
                          <Grid item>
                            <Typography variant="h5" align="center">
                              {card.description}
                            </Typography>
                          </Grid>
                          <Grid item>{modal}</Grid>
                        </Grid>

                        <Grid item xs sm={10} align="center">
                          {/* mettendo xs={10} scazza */}
                          <img
                            src={`${img_base_url}${card.img_src}`}
                            alt={card.img_alt}
                            className="imgTile"
                          />
                        </Grid>

                        {/* <Grid
                        item
                        container
                        direction="column"
                        justify="flex-end"
                        alignItems="center"
                        className="mediaContainer"
                      >
                        <img
                          src={`${img_base_url}${card.img_src}`}
                          alt={card.img_alt}
                          className="imgTile"
                        />
                      </Grid> */}
                        <Grid item align="center">
                          <FormControlLabel
                            key={card.value}
                            value={card.value}
                            control={<Radio />}
                            label={card.label}
                          />
                          <div>{nb}</div>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </RadioGroup>
        </Grid>
      </Grid>
    </FormControl>
  );
};
