import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
// import FormLabel from "@material-ui/core/FormLabel";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";

import _ from "lodash";
import { Address } from "./../Address";
import { AutocompleteList } from "./../AutocompleteList";

import { getSiteParams } from "../../configuration/wizardConfiguration";
import { m, detectBrowserLanguage } from "../../utils/i18n";
import Cookies from 'js-cookie';
const siteParams = getSiteParams();

export const ReviewPdf = (props) => {
  const {
    setNavAction,
    formData,
    setFormValues,
    setForm,
    navigation,
    stepData,
    steps,
    setLoadingStepData,
  } = props;
  const { name, email } = formData;
  const {
    buttonLabel,
    privacyName,
    privacyLabel,
    privacyValue,
    privacyLink,
    privacyName2,
    privacyLabel2,
    privacyValue2,
    privacyLink2,
    rememberMeName,
    rememberMeValue,
    rememberMeActive,
    rememberMeLabel,
    rememberMeLink    
  } = stepData.params;

  const [errors, setErrors] = React.useState({});

  // http://emailregex.com/
  function validateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  // https://bit.ly/3DD7Irt
  function validatePostalCode(mail) {
    const lang = detectBrowserLanguage();

    const regex = lang === "it" ? /^[0-9]{5}$/ : /(.|\s)*\S(.|\s)*/;
    // if (/^[0-9]{5}$/.test(mail)) {

    if (regex.test(mail)) {
      return true;
    }
    return false;
  }

  const handleGetPdf = (event) => {
    const isEmailValid = validateEmail(formData.email);
    const isPostalCodeValid = validatePostalCode(formData.postalCode);

    // verifica segnalazione 15.06.2021 -> può aver senso resettare err prima
    // di questi controlli per ripulire eventualu errori incastrati?
    let err = { ...errors };

    if (!siteParams.fill_form_enabled) {
      return;
    }

    if (_.isEmpty(formData.userCategory)) {
      err.userCategory = m("common.error.required_userCategory");
      // setErrors({ name: m("common.error.required_name") });
      setErrors(err);
      event.preventDefault();
    }

    if (!isEmailValid) {
      err.email = m("common.error.required_or_invalid_email");
      // { email: m("common.error.required_or_invalid_email") }
      setErrors(err);
      event.preventDefault();
    }

    if (_.isEmpty(formData.name)) {
      err.name = m("common.error.required_name");
      // setErrors({ name: m("common.error.required_name") });
      setErrors(err);
      event.preventDefault();
    }

    if (_.isEmpty(formData.fullAddress)) {
      err.fullAddress = m("common.error.required_full_address");
      // setErrors({ name: m("common.error.required_name") });
      setErrors(err);
      event.preventDefault();
    }

    if (!isPostalCodeValid) {
      err.postalCode = m("common.error.required_or_invalid_postal_code");
      // setErrors({ name: m("common.error.required_name") });
      setErrors(err);
      event.preventDefault();
    }

    if (_.isEmpty(formData.province)) {
      err.province = m("common.error.required_province");
      // setErrors({ name: m("common.error.required_name") });
      setErrors(err);
      event.preventDefault();
    }
  };

  const handleEmailChange = (event) => {
    let updatedFormData = { ...formData };
    updatedFormData["email"] = event.target.value.trim();
    setFormValues({ ...updatedFormData });
  };

  const handleFocus = (event) => {
    let err = { ...errors };
    err[event.target.name] = "";
    setErrors(err);
  };

  const getPdfUrl = (event) => {
    // const filters = _.omitBy(formData, _.isEmpty);

    const cookieValue = Cookies.get('ckUserData');
    formData['ck'] = cookieValue;

    // Removes NOPOST
    const filters = _.pickBy(formData, (value, key) => {
      return  (!_.includes(key, '_NOPOST_'));
    });

    const qs = Object.keys(filters)
      .map((key) => `${key}=${encodeURIComponent(filters[key])}`)
      .join("&");

    const url = `${siteParams.pdf_url}?${qs}`;

    return url;
  };

  const props_and_errrors = {
    ...props,
    ...{ errors: errors },
    ...{ setErrors: setErrors },
    ...{ listWidget: stepData.params.categoryWidget },
    ...{ defaultValue: formData[stepData.params.categoryWidget.name]},       
  };

  return (
    <Container maxWidth="xs">
      {/* <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="position"
          name="legalEntityType"
          defaultValue="private"
        >
          <FormControlLabel
            value="private"
            control={<Radio color="primary" />}
            label={m("common.input.privateCitizen")}
          />
          <FormControlLabel
            value="company"
            control={<Radio color="primary" />}
            label={m("common.input.Company")}
          />
        </RadioGroup>
      </FormControl> */}
      {/* <div>province: {formData["province"]}</div>
      <div>provinceItem: {JSON.stringify(formData["provinceItem"])}</div>
      <div>fullAddress: {formData["fullAddress"]}</div> */}

      <AutocompleteList {...props_and_errrors} />
      <TextField
        label={m("common.input.name")}
        name="name"
        value={name}
        onChange={setForm}
        onFocus={handleFocus}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        required
        error={Boolean(errors?.name)}
        helperText={errors?.name}
        fullWidth
      />
      <TextField
        label="E-mail"
        name="email"
        value={email}
        onChange={handleEmailChange}
        onFocus={handleFocus}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        required
        error={Boolean(errors?.email)}
        helperText={errors?.email}
        fullWidth
      />
      <Address {...props_and_errrors} />

      {(rememberMeActive) ? (
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={formData[rememberMeValue]} 
            name={rememberMeName}
            value={rememberMeValue}
            onChange={setForm}
          />
        }
        label={
          <Link target="_blank" href={rememberMeLink}>
            {rememberMeLabel}
          </Link>
        }        
      />
      ) : (
        <></>
      )

      }


      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={formData[privacyValue]} 
            name={privacyName}
            value={privacyValue}
            onChange={setForm}
          />
        }
        label={
          <Link target="_blank" href={privacyLink}>
            {privacyLabel}
          </Link>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={formData[privacyValue2]} 
            name={privacyName2}
            value={privacyValue2}
            onChange={setForm}
          />
        }
        label={
          <Link target="_blank" href={privacyLink2}>
            {privacyLabel2}
          </Link>
        }
      />
      <Box align="center" my={2}>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginRight: "1rem" }}
          onClick={() => {
            // var a = stepData;
            // var b = steps;
            const currentStepIndex = parseInt(stepData["id"]) - 1;
            let key = "";
            try {
              key = steps[currentStepIndex - 2].params.radioGroup.name;
            } catch (e) {}

            // ATTENZIONE! la gestione del jumptostep c'è solo in questo componente; se serve va estesa a tutti i bottoni "BACK"
            if (
              _.has(formData, `${key}___jumptostep`) &&
              !_.isEmpty(formData[`${key}___jumptostep`]) &&
              parseInt(formData[`${key}___jumptostep`]) === currentStepIndex
            ) {
              navigation.go(currentStepIndex - 2);
            } else {

                setLoadingStepData(true); // va qui perchè il next scatena referesh                     
                setNavAction("prev");
                // navigation.previous();
            }

            setLoadingStepData(true);
            console.log("loadingStepData true ");
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !(formData[privacyValue] && formData[privacyValue2]) &&
            siteParams.fill_form_enabled
          }
          target="_blank"
          href={getPdfUrl()}
          onClick={handleGetPdf}
        >
          {m(buttonLabel)}
        </Button>
      </Box>
    </Container>
  );
};
