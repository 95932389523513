import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import SimpleModal from "./SimpleModal";
import { getSiteParams } from "../../configuration/wizardConfiguration";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles({
  mediaContainer: {
    height: 270,
  },
  tileDescription: {
    margin: "8px",
  },
});

export const SingleCardMultiRadioGroupStep = ({
  formData,
  setFormValues,
  setForm,
  navigation,
  stepData,
  steps,
}) => {
  const { img_base_url } = getSiteParams();
  const classes = useStyles();
  const { radioGroup } = stepData.params;

  const additionalImageDx = stepData.additionalImageDx;
  const additionalTextDx = stepData.additionalTextDx;

  const modal =
  radioGroup.infoText && radioGroup.infoText !== "" ? (
    <SimpleModal
      {...{ title: radioGroup.label, content: radioGroup.infoText }}
    />
  ) : (
    ""
  );


  return (
    <Grid
      item
      container
      direction="column"
      justify="space-around"
      alignItems="stretch"
    >
      <Grid
        item
        container
        spacing={0}
        alignContent="center"
        alignItems="flex-start"
        justify="center"
      >
        <Grid
          key="sx"
          item
          container
          direction="row"
          xs={12}
          sm={6}
          md={4}
          lg={4}
          alignContent="center"
          // justifyContent="center"
          justify={additionalImageDx ? "flex-end" : "space-around"}
        >
          <FormControl component="fieldset">
            <RadioGroup
              key={radioGroup.name}
              aria-label={radioGroup.label}
              name={radioGroup.name}
              value={formData[radioGroup.value]}
              onChange={setForm}
            >
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid
                  item
                  container
                  direction="column"
                  justify="flex-end"
                  alignItems="center"
                  className={classes.mediaContainer}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      align="center"
                      className={classes.tileDescription}
                    >
                      {radioGroup.description}
                    </Typography>
                    <img
                      src={`${img_base_url}${radioGroup.img_src}`}
                      alt={radioGroup.img_alt}
                      className="imgTileCollection"
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Box width="100%" justifyContent="center" my={2}>
                    {radioGroup.options.map((option, index) => {
                      return (
                        <>
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.value}
                          />
                          <br key={`${option.value}b`} />
                        </>
                      );
                    })}
                  </Box>
                  <Box>{modal}</Box>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        {additionalImageDx ? (
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={4}
            lg={4}
            alignContent="center"
            justify={additionalImageDx ? "flex-start" : "space-around"}
          >
            <FormControl component="fieldset">
              <RadioGroup
                key={radioGroup.name}
                aria-label={radioGroup.label}
                name={radioGroup.name}
                value={formData[radioGroup.value]}
                onChange={setForm}
              >
                <Grid item container direction="column" alignItems="center">
                  <Grid
                    item
                    container
                    direction="column"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.mediaContainer}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        align="center"
                        className={classes.tileDescription}
                      >
                        {radioGroup.description}
                      </Typography>
                      <img
                        src={`${img_base_url}${additionalImageDx}`}
                        alt={radioGroup.img_alt}
                        className="imgTileCollection"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" alignItems="center">
                    {/* <Box width="100%" justifyContent="center"> */}
                    <Box width="100%">
                      <Typography variant="body1" align="left">
                        <ReactMarkdown children={additionalTextDx} />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};
