import _ from "lodash";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { evalExpression } from "../utils/parseUtils";
import { m } from "../utils/i18n";
import { getSiteParams } from "../configuration/wizardConfiguration";
const { v4: uuidv4 } = require('uuid');
const { summary_background_color } = getSiteParams();

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    background: summary_background_color,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const SummaryCard = ({ formData, steps }) => {
  const classes = useStyles();

  const summaryItems = _.filter(
    steps.map(function (step, index) {





      // from this
      // {
      // dampermotoplenum__qty__XXXAAA2__summary_desc: 'Serranda Circolare Motorizzata con By pass circolare|||Nr.1 Ø100',
      // dampermotoplenum__qty__XXXAAA3__summary_desc: 'Serranda Circolare Motorizzata con By pass circolare|||Nr.3 Ø20',
      // dampermotoplenum__qty__XXXAAA4__summary_desc: 'Serranda Circolare Motorizzata con By pass circolare|||Nr.5 Ø3330',
      // dampermotoplenum__qty__XXXAAA5__summary_desc: 'Serranda Circolare Motorizzata con By pass circolare|||Nr.4 Ø400',
      // dampermotoplenum__qty__XXXAAA6__summary_desc: 'Serranda Circolare Motorizzata con By pass circolare|||Nr.6 Ø600',
      // dampermotoplenum__qty__XXXAAA7__summary_desc: 'Serranda Circolare Motorizzata con By pass circolare|||Nr.1 Ø100',

      // dampermotoplenum__qty__XXXAAA2__summary_desc: 'Serranda Motorizzata con By pass circolare|||Nr.12 Ø300',
      // dampermotoplenum__qty__XXXAAA3__summary_desc: 'Serranda Motorizzata con By pass circolare|||Nr.32 Ø40',
      // dampermotoplenum__qty__XXXAAA4__summary_desc: 'Serranda Motorizzata con By pass circolare|||Nr.1 Ø3530',
      // dampermotoplenum__qty__XXXAAA5__summary_desc: 'Serranda Motorizzata con By pass circolare|||Nr.1 Ø450',
      // dampermotoplenum__qty__XXXAAA6__summary_desc: 'Serranda Motorizzata con By pass circolare|||Nr.3 Ø660',
      // dampermotoplenum__qty__XXXAAA7__summary_desc: 'Serranda Motorizzata con By pass circolare|||Nr.3 Ø170',


      // }

      // get this

      // [
      // {
      // 	group:'Serranda Circolare Motorizzata con By pass circolare'
      // 	values:'Nr.1 Ø100, Nr.3 Ø20, Nr.5 Ø3330, Nr.4 Ø400, Nr.6 Ø600, Nr.1 Ø100'
      // },

      // {
      // 	group:'Serranda Motorizzata con By pass circolare'
      // 	values:'Nr.12 Ø300, Nr.32 Ø40, Nr.1 Ø3530, Nr.1 Ø450, Nr.3 Ø660,Nr.3 Ø170
      // },

      // ]
      function groupElements(inputObject) {

        const outputArray = [];

        // Grouping by the first part of the summary_desc
        const groupedData = Object.values(inputObject).reduce((acc, desc) => {
          const [group, value] = desc.split('|||');
          if (!acc[group]) {
            acc[group] = [];
          }
          acc[group].push(value);
          return acc;
        }, {});

        // Transforming groupedData into the desired format
        for (const group in groupedData) {
          outputArray.push({
            group,
            values: groupedData[group].join(', '),
          });
        }
        return outputArray;

      }


      function extractElements(obj, keyPrefix, keySuffix) {
        const result = {};

        for (const [key, value] of Object.entries(obj)) {
          if (
            key.startsWith(keyPrefix) &&
            key.endsWith(keySuffix) &&
            value !== null &&
            value !== undefined &&
            value !== ''
          ) {
            result[key] = value;
          }
        }

        return result;
      }


      switch (step.summaryItemType) {
        case "KEY":
          // if (!_.isEmpty(formData[step.summaryKeyName])) {
          //   setShowSummary(true);
          // }
          return formData[step.summaryKeyName] ? (
            <div key={step.summaryKeyName}>
              <Typography variant="body2" component="p">
                {formData[step.summaryKeyName]}
              </Typography>
              <Divider />
            </div>
          ) : null;
        case "YES_NO":
          return formData[step.summaryKeyName] ? (
            <div key={step.summaryKeyName}>
              <Typography variant="body2" component="p">
                {formData[step.summaryKeyName]}
              </Typography>
              <Divider />
            </div>
          ) : null;
        case "KEYPRICE":
          // if (!_.isEmpty(formData[step.summaryKeyName])) {
          //   setShowSummary(true);
          // }
          const codeKey = `${step.summaryKeyName}___code`;
          const priceKey = `${step.summaryKeyName}___price`;
          return formData[step.summaryKeyName] ? (
            <div key={step.summaryKeyName}>
              <Typography variant="body2" component="p">
                {formData[codeKey]} - {formData[step.summaryKeyName]} (
                {formData[priceKey]})
              </Typography>
              <Divider />
            </div>
          ) : null;
        case "MULTICOLUMN":
          return formData[step.summaryKeyName] ? (
            <div key={step.summaryKeyName}>
              <Typography variant="body2" component="p">
                {formData[step.summaryKeyName]}
              </Typography>
              <Divider />
            </div>
          ) : null;

        case "MULTIINPUT":
          return (
            <div key={step.summaryKeyName}>
              {step.params.fields.map((field) => {
                // formData[step.summaryKeyName]
                return !_.isEmpty(formData[field.name]) ? (
                  <div key={field.name}>
                    <Typography variant="body2" component="p">
                      {field.label}:{" "}
                      {field.type === "text" ? formData[field.name] : ""}
                      {field.type === "list"
                        ? _.find(field.options, { value: formData[field.name] })
                            .label
                        : ""}
                    </Typography>
                    <Divider />
                  </div>
                ) : (
                  ""
                );
              })}
            </div>
          );

        case "DYNAMICGRID":
          return (
            <div key={step.summaryKeyName}>
              <Typography variant="body2" component="p">
                {step.params.radioGroup.label}
                {formData[step.params.radioGroup.name].map(
                  (field, fieldidx) => {
                    return (
                      <div key={fieldidx}>
                        - {field[0]} {step.params.rowText1} {field[1]}{" "}
                        {step.params.rowText2}
                      </div>
                    );
                    // formData[step.summaryKeyName]
                    // return !_.isEmpty(formData[field.name]) ? (
                    //   <div key={field.name}>
                    //     <Typography variant="body2" component="p">
                    //       {field.label}:{" "}
                    //       {field.type === "text" ? formData[field.name] : ""}
                    //       {field.type === "list"
                    //         ? _.find(field.options, { value: formData[field.name] })
                    //             .label
                    //         : ""}
                    //     </Typography>
                    //     <Divider />
                    //   </div>
                    // ) : (
                    //   ""
                    // );
                  }
                )}
              </Typography>
            </div>
          );

        case "GROUPED":
          let groups = _.uniqBy(step.params.radioGroupCollection, "name");

          let options = [];
          _.forEach(groups, function (group) {
            let item = { ...group };

            const pref = item.value.split("{{NNN}}")[0];
            const groupLabel = item.label.split("{{NNN}}")[0];

            // all Items in formData for this group
            const keysForGroup = Object.keys(formData).filter(function (
              formDataKey
            ) {
              return formDataKey.indexOf(pref) === 0;
            });
            const itemsForGroup = _.pick(formData, keysForGroup);

            let groupOptions = item.options.map((option) => {
              let opt = { ...option };
              opt.quantity = 0;
              opt.groupLabel = groupLabel;

              // conto quanti per questa option e incremento la quantità...
              _.forIn(itemsForGroup, function (value, key) {
                if (value === option.value) {
                  opt.quantity++;
                }
              });

              return opt.quantity > 0 ? opt : "";
            });

            options = _.concat(options, groupOptions);
          });

          // filter
          options = _.filter(options, function (o) {
            return !_.isEmpty(o);
          });

          const { visibleIf, quantity, text } = step.params.summaryAddendum;
          const show = evalExpression(visibleIf, formData);
          if (show) {
            options.push({
              quantity: evalExpression(quantity, formData),
              groupLabel: text,
              value: "",
            });
            // addendum = `Nr. ${evalExpression(quantity)} ${text}`;
          }

          return !_.isEmpty(options) ? (
            <div>
              {options.map(function (opt, index) {
                let childKey = `${opt.groupLabel}${opt.value}`;
                return (
                  <div key={childKey}>
                    <Typography variant="body2" component="p">
                      Nr. {opt.quantity} {opt.groupLabel} {opt.value}
                    </Typography>
                    <Divider />
                  </div>
                );
              })}
            </div>
          ) : null;

        case "QUANTITY":
          return formData[step.params.radioGroup.quantityValue] > 0 ? (
            <div key={step.id}>
              <Typography variant="body2" component="p">
                {`Nr. ${formData[step.params.radioGroup.quantityValue]} ${
                  step.params.radioGroup.label
                }`}
              </Typography>
              <Divider />
            </div>
          ) : null;

        case "MULTIQUANTITY":
          const items = extractElements(formData, `${step.params.radioGroup.code}__qty__`, "__NOPOST_smry");
          const groupedItems = groupElements(items);
          return !_.isEmpty(groupedItems) ? (          
            groupedItems.map(function (item, index) {
              if (item.values.length<10) {
                return(
                  <div key={uuidv4()}>
                    <Typography variant="body2" component="p">
                      {`${item.group}:`} &nbsp;{item.values}
                    </Typography>
                    <Divider />
                  </div>
                );

              } else {

                // split in lines 
                const MAX_LINE_LENGTH = 100;
                const stringArray = item.values.split(',');
                let lines = [];
                let token = "";
                for (let i = 0; i < stringArray.length; i ++) {
                  const comma = (i < stringArray.length-1) ? ", " : "";
                  if (token.length + stringArray[i].length < MAX_LINE_LENGTH){    
                    token += `${stringArray[i]}${comma}`;
                  } else {
                    lines.push(token);
                    token = `${stringArray[i]}${comma}`;
                  } 
                }
                lines.push(token);                

                // const lines = ["s", "b"]
                return(
                  <div key={uuidv4()}>
                    <Typography variant="body2" component="p">
                      <div>{`${item.group}:`}</div>
                      {lines.map((line, index) => {
                          return (
                            <Box style={{ marginLeft: '10px' }}>
                              {line}
                            
                            </Box>

                          )
                        })

                      }
                      {/* <Box style={{ marginLeft: '10px' }}>
                        {item.values}ff{br}fffff
                        {brText}
                      </Box> */}
                    </Typography>
                    <Divider />
                  </div>
                );

              }

            })

          ) : null;

        // case "MULTIQUANTITYDOUBLE":
        //   const items = extractElements(formData, `${step.params.radioGroup.code}__qty__`, "__summary_desc");
        //   const groupedItems = groupElements(items);
        //   return !_.isEmpty(groupedItems) ? (          
        //     groupedItems.map(function (item, index) {
        //       return(
        //         <div key={uuidv4()}>
        //           <Typography variant="body2" component="p">
        //             <div>{`${item.group}:`}</div>
        //             <div>&nbsp;&nbsp;&nbsp;{item.values}</div>
        //           </Typography>
        //           <Divider />
        //         </div>
        //       );

        //     })

          // ) : null;





          case "QUANTITY_AND_KEY":
          const item1 =
            formData[step.params.radioGroup.quantityValue] > 0 ? (
              <div key={step.id}>
                <Typography variant="body2" component="p">
                  {`Nr. ${formData[step.params.radioGroup.quantityValue]} ${
                    step.params.radioGroup.label
                  }`}
                </Typography>
                <Divider />
              </div>
            ) : null;

          const item2 = formData[step.optionSummaryKeyName] ? (
            <div key={step.optionSummaryKeyName}>
              <Typography variant="body2" component="p">
                {formData[step.optionSummaryKeyName]}
              </Typography>
              <Divider />
            </div>
          ) : null;

          // let res = [item1, item2].join("");

          // return res !== "" ? res : null;
          // return [item2];
          return [item1, item2];

        default:
          return null;
      }
    }),
    function (o) {
      return !_.isEmpty(o);
    }
  );

  return summaryItems.length > 0 ? (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Box align="center">
          <Typography variant="subtitle1" component="p">
            <b>{m("common.summary")}</b>
          </Typography>
        </Box>

        <Divider />
        {summaryItems}
      </CardContent>
    </Card>
  ) : (
    <></>
  );
};

//   return (
//     <Card className={classes.root} variant="outlined">
//       <CardContent>
//         {steps.map(function (step, index) {
//           return toShow(step) ? (
//             step.groupedInSummary ? (
//               <div>
//                 {step.params.radioGroupCollection.map((group, index) => {
//                   let item = { ...group };

//                   const pref = item.value.split("{{NNN}}")[0];
//                   const groupLabel = item.label.split("{{NNN}}")[0];

//                   // all Items in formData for this group
//                   const keysForGroup = Object.keys(formData).filter(function (
//                     formDataKey
//                   ) {
//                     return formDataKey.indexOf(pref) === 0;
//                   });
//                   const itemsForGroup = _.pick(formData, keysForGroup);

//                   return item.options.map((option) => {
//                     let opt = { ...option };
//                     opt.quantity = 0;

//                     // conto quanti per questa option e incremento la quantità... que filtro va fuori da option
//                     _.forIn(itemsForGroup, function (value, key) {
//                       if (value === option.value) {
//                         opt.quantity++;
//                       }
//                     });

//                     return opt.quantity > 0 ? (
//                       <div key={step.summaryKeyName}>
//                         <Typography variant="body2" component="p">
//                           Nr. {opt.quantity} {groupLabel} {opt.value}
//                         </Typography>
//                         <Divider />
//                       </div>
//                     ) : (
//                       ""
//                     );
//                   });
//                 })}
//               </div>
//             ) : (
//               <div key={step.summaryKeyName}>
//                 <Typography variant="body2" component="p">
//                   {formData[step.summaryKeyName]}
//                 </Typography>
//                 <Divider />
//               </div>
//             )
//           ) : (
//             ""
//           );
//         })}
//       </CardContent>

//     </Card>
//   );
// };

/*


/*
        <List component="nav" aria-label="Summary">
          {Object.keys(formData).map(function (key, index) {
            return formData[key] === "" ? (
              ""
            ) : (
              <>
                <ListItem button>
                  <ListItemText primary={formData[key]} />
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>


*/
