import React from "react";

// import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import SimpleModal from "./SimpleModal";
import { makeStyles } from "@material-ui/core/styles";

import { getSiteParams } from "../../configuration/wizardConfiguration";

// import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles({
  imgDescription: {
    marginBottom: "40px",
    marginTop: "10px",
  },
});

const Quantity = ({ formData, setFormValues, setForm, stepData }) => {
  const { radioGroup } = stepData.params;
  // const { cards } = radioGroup;

  const updateQuantity = (updatedQ) => {
    let updatedFormData = { ...formData };

    if (isNaN(updatedQ) || updatedQ <= 0) {
      updatedFormData[radioGroup.quantityValue] = 0;
      updatedFormData[`${radioGroup.name}__code`] = "";
      updatedFormData[`${radioGroup.name}__price`] = "";
      updatedFormData[`${radioGroup.name}__description`] = "";
    } else {
      updatedFormData[radioGroup.quantityValue] = updatedQ;
      updatedFormData[`${radioGroup.name}__code`] = radioGroup.code;
      updatedFormData[`${radioGroup.name}__price`] = radioGroup.price;
      updatedFormData[`${radioGroup.name}__description`] =
        radioGroup.cards[0].description;
    }
    setFormValues({ ...updatedFormData });
  };

  const deltaQuantity = (delta) => {
    let updatedQ = formData[radioGroup.quantityValue] + delta;
    updateQuantity(updatedQ);
  };

  const setQuantity = (e) => {
    updateQuantity(parseInt(e.target.value));
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          deltaQuantity(-1);
        }}
      >
        <RemoveIcon />
      </IconButton>
      <TextField
        label={radioGroup.quantityLabel}
        name={radioGroup.quantityName}
        value={formData[radioGroup.quantityValue]}
        onChange={setQuantity}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        type="text"
        style={{ width: 70 }}
        inputProps={{
          min: 0,
          max: 100,
          style: {
            textAlign: "center",
            fontSize: "20px",
          },
        }}

        // inputProps={{ size: "3" }}
      />
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          deltaQuantity(+1);
        }}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export const CardsQuantityGroupStep = (props) => {
  const classes = useStyles();

  const { formData, setForm, stepData } = props;
  const { radioGroup } = stepData.params;
  const { cards } = radioGroup;
  const { img_base_url } = getSiteParams();

  return (
    <FormControl component="fieldset" fullWidth={true}>
      {/* <FormLabel component="legend">Brand</FormLabel> */}
      <RadioGroup
        aria-label={radioGroup.label}
        name={radioGroup.name}
        value={formData[radioGroup.value]}
        onChange={setForm}
      >
        <Grid
          container
          spacing={1}
          alignContent="center"
          justify="space-around"
        >
          {cards.map((card, card_index) => {
            const modal =
              card.infoText && card.infoText !== "" ? (
                <SimpleModal
                  {...{ title: card.description, content: card.infoText }}
                />
              ) : (
                ""
              );

            return (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                xs={8}
                key={card_index}
              >
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  justify="center"
                  alignItems="center"
                  className={classes.imgDescription}
                >
                  <Grid item>{/* bilanca la i */}</Grid>
                  <Grid item>
                    <Typography variant="h5" align="center">
                      {card.description}
                    </Typography>
                  </Grid>
                  <Grid item>{modal}</Grid>
                </Grid>

                <Grid item xs sm={5} align="center">
                  {/* mettendo xs={10} scazza */}
                  <img
                    src={`${img_base_url}${card.img_src}`}
                    alt={card.img_alt}
                    className="imgTile"
                  />
                </Grid>

                <Grid item>
                  <Quantity {...props} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};
