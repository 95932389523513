import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
// import FormLabel from "@material-ui/core/FormLabel";

// import _ from "lodash";
// import { makeStyles } from "@material-ui/core/styles";
// import { getSiteParams } from "../../configuration/wizardConfiguration";
// import { m, detectBrowserLanguage } from "../../utils/i18n";
import { AutocompleteList } from "./../AutocompleteList";
import { evalExpression } from "../../utils/parseUtils";
import  {VideoWidget} from "./VideoWidget";
import Box from "@material-ui/core/Box";
import SimpleModal from "./SimpleModal";
import { Grid } from "@material-ui/core";



export const MultiInputStep = (props) => {
  const { formData, setFormValues, stepData, errors, setErrors } = props;

  const { fields, infoText, title } = stepData.params;


  const handleChange = (event) => {
    // var err = {};
    // err.shortsidelength = "ciccio";
    // setErrors({ ...err });

    let updatedFormData = { ...formData };
    updatedFormData[event.target.name] = event.target.value;
    setFormValues({ ...updatedFormData });
  };

  const handleFocus = (event) => {
    let err = { ...errors };
    err[event.target.name] = "";
    setErrors(err);
  };


  const modal =
    infoText && infoText !== "" ? (
      <SimpleModal
        {...{ title: title, content: infoText }}
      />
    ) : (
      ""
    );



  return (
    <Container maxWidth="sm">
    
      {/* <FormControl className={classes.formControl}> */}
      {fields.map((field) => {
        // stuff
        const showIfCondition = field.showIf;
        const show = evalExpression(showIfCondition, formData);

        if (show) {
          switch (field.type) {
            case "video":
              return (
                <VideoWidget videoSrc={field.src}/>
                // <TextField
                //   label={field.label}
                //   name={field.name}
                //   value={formData[field.name]}
                //   onChange={handleChange}
                //   onFocus={handleFocus}
                //   margin="normal"
                //   variant="outlined"
                //   autoComplete="off"
                //   required
                //   error={Boolean(errors[field.name])}
                //   helperText={errors[field.name]}
                //   fullWidth
                // />
              );
            case "text":
              return (
                <Container maxWidth="xs">

                <TextField
                  label={field.label}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  required
                  error={Boolean(errors[field.name])}
                  helperText={errors[field.name]}
                  fullWidth
                />
                </Container>
              );

            case "list":
              var selected;
              if (
                formData[field.name] !== undefined &&
                formData[field.name] !== ""
              ) {
                selected = _.find(field.options, {
                  value: formData[field.name],
                });
              }

              const props_and_errrors = {
                ...props,
                ...{ errors: errors },
                ...{ setErrors: setErrors },
                ...{ listWidget: field },
                ...{ defaultValue: selected },
              };
              // defaultValue={{ title: "The Godfather", year: 1972 }}
              return (
                <Container maxWidth="xs">

                  <AutocompleteList {...props_and_errrors} />
                </Container>

                // <Select
                //   labelId={field.label}
                //   id={field.name}
                //   value={formData[field.name]}
                //   onChange={handleChange}
                //   displayEmpty
                //   fullWidth
                //   className={classes.selectEmpty}
                //   IconComponent={(props) => (
                //     <ArrowDropDownIcon fontSize="large" {...props} />
                //   )}
                // >
                //   <MenuItem value="">
                //     <em>{m("common.select.placeholder")}</em>
                //   </MenuItem>
                //   {field.options.map((data, index) => {
                //     return (
                //       <MenuItem key={data.value} value={data.value}>
                //         {data.label}{" "}
                //       </MenuItem>
                //     );
                //   })}
                // </Select>
              );
            default:
              return <>check default</>;
          }
        }
        return <></>;
      })}

                  <Grid
                    key="popover"
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Box m={3}>{modal}</Box>
                    </Grid>
                  </Grid>

      {/* </FormControl> */}
    </Container>
  );
};
