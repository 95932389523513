import React from "react";
import _ from "lodash";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import SimpleModal from "./SimpleModal";
import { getSiteParams } from "../../configuration/wizardConfiguration";

const { img_base_url, theme_palette_primary_main } = getSiteParams();


const useStyles = makeStyles((theme) => ({
  divider: {
    // Theme Color, or use css color in quote
    // background: theme.palette.divider,
    background: theme_palette_primary_main,
  },
  blocksContainer: {
    paddingRight: "50px",
    paddingLeft: "60px",
  },
}));

const Quantity = ({ formData, setFormValues, card }) => {
  // const { cards } = radioGroup;
  const updateQuantity = (updatedQ) => {
    let updatedFormData = { ...formData };

    if (isNaN(updatedQ) || updatedQ <= 0) {
      updatedFormData[card.value] = 0;
      updatedFormData[`${card.value}__code`] = "";
      // updatedFormData[`${card.value}__description`] = "";
      updatedFormData[`${card.value}__NOPOST_smry`] = "";      
    } else {
      updatedFormData[card.value] = updatedQ;
      updatedFormData[`${card.value}__code`] = card.name;
      // updatedFormData[`${card.value}__description`] = card.label;
      updatedFormData[`${card.value}__NOPOST_smry`] = `${card.label}|||Nr. ${updatedQ}`;      
    }
    setFormValues({ ...updatedFormData });
  };

  const deltaQuantity = (delta) => {
    let updatedQ = formData[card.value] + delta;
    updateQuantity(updatedQ);
  };

  const setQuantity = (e) => {
    updateQuantity(parseInt(e.target.value));
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          deltaQuantity(-1);
        }}
      >
        <RemoveIcon />
      </IconButton>
      <TextField
        // label={option.label}
        // label={"Q.tà"}
        name={card.name}
        value={formData[card.value]}
        onChange={setQuantity}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        type="text"
        style={{ width: 70 }}
        inputProps={{
          min: 0,
          max: 100,
          style: {
            textAlign: "center",
            fontSize: "20px",
          },
        }}

        // inputProps={{ size: "3" }}
      />
      <IconButton
        color="primary"
        component="span"
        onClick={() => {
          deltaQuantity(+1);
        }}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export const CardsQuantityDoubleGroupStep = ({
  formData,
  setForm,
  navigation,
  stepData,
  setFormValues,
}) => {
  const { radioGroup } = stepData.params;
  const { nbText } = stepData.params;  
  const { options, groupLeft, groupRight } = radioGroup;
  const classes = useStyles();

  
  // undefied quantities to 0  
  React.useEffect(() => {
    let updatedFormData = { ...formData };
    options.forEach((option) => {
      updatedFormData[option.value] = (updatedFormData[option.value] === undefined) ? 0 : updatedFormData[option.value];
    }); 
    
    // cards.forEach((card, card_index) => {
    //   const options  = card.options;
    //   options.forEach((option, opt_index) => {
    //     updatedFormData[option.value] = (updatedFormData[option.value] === undefined) ? 0 : updatedFormData[option.value];
    //   }); 
    // });


    setFormValues({ ...updatedFormData });        
  // }); // run every render.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]); // run only once - each time steo id changes.  
  // }, [stepData.id]); // run only once - each time steo id changes.  

  const nbGlobal =
    nbText && nbText !== "" ? (
      <Box
        display="flex"
        color="red"
        width="100%"
        justifyContent="center"
        pt={3}
      >
        <Typography
          variant="body1"
          style={{ fontWeight: 600 }}
          align="center"
        >
          {nbText}
        </Typography>
      </Box>
    ) : (
      ""
    );


  return (
    <FormControl component="fieldset" fullWidth={true}>
      {/* <FormLabel component="legend">Brand</FormLabel> */}
      <RadioGroup
        aria-label={radioGroup.label}
        name={radioGroup.name}
        value={formData[radioGroup.value]}
        onChange={setForm}
      >
        <Grid
          item
          container
          xs={12}
          spacing={2}
          alignContent="center"
          justify="space-around"
          className={classes.blocksContainer}
        >
          <Grid item container xs={12} sm={6} direction="column" spacing={0} id="leftContainer">
            {/* Left panel title */}
            <Grid
              item
              container
              direction="row"
              spacing={1}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="body1" align="left">
                  {groupLeft.text}
                </Typography>
              </Grid>
              <Grid item>
                <img
                  src={`${img_base_url}${groupLeft.img_src}`}
                  alt={groupLeft.text}
                  width="40"
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider classes={{ root: classes.divider }} />
            </Grid>
            
            {/* <Grid item container xs={12} sm={6} direction="row" spacing={0} id="leftCardsContainer"> */}
             {/* sm={12} md={3} */}
            <Grid container direction="row"  id="leftCardsContainer" 
            justifyContent="flex-start"
            spacing={1} 
            >
              {/* <Grid container item xs={4}>
                ciccio
              </Grid>
              <Grid container item xs={4}>
                ciccio
              </Grid>
              <Grid container item xs={4}>
                ciccio
              </Grid> */}
              {_.filter(options, { group: groupLeft.value }).map(
                (card, card_index) => {
                  const modal =
                    card.infoText && card.infoText !== "" ? (
                      <SimpleModal
                        {...{ title: card.label, content: card.infoText }}
                      />
                    ) : (
                      ""
                    );

                  return (
                    <Grid
                      key={card.value}
                      container
                      item                      
                      direction="column"
                      justify="flex-start"
                      alignItems="center"
                      spacing={1}
                      sm={12}
                      md={4}
                      id="leftCard"
                    >
                      <Grid item>
                        <img
                          src={`${img_base_url}${card.img_src}`}
                          alt={card.img_alt}
                          className="imgHorizontalTile"
                        />
                      </Grid>

                      <Grid item>
                        <Box mt={3} mb={-1}>
                          <Typography variant="body1" align="center">
                            {card.label}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid>
                        <Quantity {...{ formData, setFormValues, card }} />
                      </Grid>

                      <Box>{modal}</Box>
                      {/* </Grid> */}
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={6} direction="column"  id="leftContainer">
            <Grid
              item
              container
              direction="row"
              spacing={1}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="body1" align="left">
                  {groupRight.text}
                </Typography>
              </Grid>
              <Grid item>
                <img
                  src={`${img_base_url}${groupRight.img_src}`}
                  alt={groupRight.text}
                  width="40"
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider classes={{ root: classes.divider }} />
            </Grid>
            {/* <Grid item container direction="row"  id="rightCardsContainer" */}
            <Grid container direction="row"  id="rightCardsContainer" 
            justifyContent="flex-start"
            spacing={1} 
            >
            {/* <Grid item container direction="column"> */}
              {_.filter(options, { group: groupRight.value }).map(
                (card, card_index) => {
                  const modal =
                    card.infoText && card.infoText !== "" ? (
                      <SimpleModal
                        {...{ title: card.label, content: card.infoText }}
                      />
                    ) : (
                      ""
                    );

                  return (
                    <Grid
                      key={card.value}
                      container
                      item                      
                      direction="column"
                      justify="flex-start"
                      alignItems="center"
                      spacing={1}
                      sm={12}
                      md={4}
                      id="rightCard"
                    >
                      <Grid item>
                        <img
                          src={`${img_base_url}${card.img_src}`}
                          alt={card.img_alt}
                          className="imgHorizontalTile"
                        />
                      </Grid>

                      {/* <Grid container item>
                        <Grid>
                          <Typography variant="caption" align="center">
                            {card.label}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Quantity {...{ formData, setFormValues, card }} />
                        </Grid> */}
                      <Grid item>
                        <Box mt={3} mb={-1}>
                          <Typography variant="body1" align="center">
                            {card.label}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid>
                        <Quantity {...{ formData, setFormValues, card }} />
                      </Grid>

                        <Box>{modal}</Box>
                      {/* </Grid> */}
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Grid>
           <div>{nbGlobal}</div>          
        </Grid>

        {/* <Grid
            container
            spacing={3}
            alignContent="center"
            justify="space-around"
          >
            {options.map((card, card_index) => {
              const modal =
                card.infoText && card.infoText !== "" ? <SimpleModal /> : "";

              return (
                <div key={card_index}>
                  <img
                    src={`${img_base_url}${card.img_src}`}
                    alt={card.img_alt}
                  />
                  <div>{card.description}</div>
                  <FormControlLabel
                    key={card.value}
                    value={card.value}
                    control={<Radio />}
                    label={card.label}
                  />
                  <div>{modal}</div>
                </div>
              );
            })}
          </Grid> */}
      </RadioGroup>
    </FormControl>
  );
};
