import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginBottom: theme.spacing(2),
    },
  },
}));

// export const MessageSnackbar = ({ formData, steps, setShowSummary }) => {
export const MessageSnackbar = ({
  messageSnackbar,
  setMessageSnackbar,
}) => {
  const classes = useStyles();

  // const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    /*
    if (reason === 'clickaway') {
      return;
    }
*/
    setMessageSnackbar(null);

    // setMessageSnackbarOpen(false);
  };

  return (
    <div className={classes.root}>
      {/* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */}
      <Snackbar
        open={messageSnackbar!= null}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          {/* {m("common.error.step_incompleted")} */}
          {messageSnackbar}
        </Alert>
      </Snackbar>
    </div>
  );
};
