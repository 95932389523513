import React from "react";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// function createData(rows, modules) {
//   return { rows, modules };
// }

// const gridLayout = [createData(3, 2), createData(2, 2), createData(4, 5)];
// let newRowRows = 0;
// let newRowModules = 0;

export const DynamicGridStep = ({
  formData,
  setFormValues,
  setForm,
  navigation,
  stepData,
  steps,
}) => {
  const {
    radioGroup,
    param1Label,
    param2Label,
    rowText1,
    rowText2,
    buttonAddRowLabel,
  } = stepData.params;

  // const { buttonLabel } = stepData.params;

  const [newRowRows, setNewRowRows] = React.useState(1);
  const [newRowModules, setNewRowModules] = React.useState(1);
  // const [gridLayout, setGridLayout] = React.useState([]);

  let gridLayout = [...formData[radioGroup.name]];

  // [createData(3, 2), createData(2, 2), createData(4, 5)];

  // let rowsLabel = "msg_rowsLabel";
  // let modulesLabel = "msg_modulesLabel";
  // let buttonAddRowLabel = "msg_buttonAddRowLabel";

  const updateGridLayout = () => {
    let updatedFormData = { ...formData };
    updatedFormData[radioGroup.name] = [...gridLayout];
    setFormValues({ ...updatedFormData });
    console.log(formData);
    console.log(updatedFormData);
  };

  const deltaRowsQuantity = (delta) => {
    // let updatedQ = formData[radioGroup.quantityValue] + delta;
    // updateRowsQuantity(updatedQ);
    // newRowRows = newRowRows + delta;
    if (parseInt(newRowRows) + delta >= 1) {
      setNewRowRows(parseInt(newRowRows) + delta);
    }
  };

  const setRowsQuantity = (e) => {
    // newRowRows = e.target.value;
    setNewRowRows(e.target.value);
    // updateRowsQuantity(parseInt(e.target.value));

    if (e.target.value >= 1) {
      setNewRowRows(e.target.value);
    } else {
      setNewRowRows(newRowRows);
    }
  };

  const deltaModulesQuantity = (delta) => {
    if (parseInt(newRowModules) + delta >= 1) {
      setNewRowModules(parseInt(newRowModules) + delta);
    }
  };

  const setModulesQuantity = (e) => {
    if (e.target.value >= 1) {
      setNewRowModules(e.target.value);
    } else {
      setNewRowModules(newRowModules);
    }
  };

  const addRow = () => {
    let currGridLayout = [...gridLayout];

    // const item = { rows: newRowRows, modules: newRowModules };
    const item = [newRowRows, newRowModules];
    // currGridLayout.push(...item);
    currGridLayout = [...currGridLayout, item];

    // setGridLayout([...currGridLayout]);
    gridLayout = [...currGridLayout];

    // gridLayout.push(createData(newRowRows, newRowModules));
    setNewRowRows(1);
    setNewRowModules(1);
    updateGridLayout();
  };

  const removeRow = (idx) => {
    let currGridLayout = [...gridLayout];
    currGridLayout.splice(idx, 1);

    // setGridLayout([...currGridLayout]);
    gridLayout = [...currGridLayout];
    updateGridLayout();
    // gridLayout.splice(idx, 1);
  };

  return (
    // <Container maxWidth="sm">
    <Box display="flex" alignItems="center" justifyContent="center">
      <FormControl component="fieldset">
        <Grid item container xs={12} spacing={2} id="ciccios">
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" justifyContent="right">
              <IconButton
                color="primary"
                component="span"
                onClick={() => {
                  deltaRowsQuantity(-1);
                }}
              >
                <RemoveIcon />
              </IconButton>
              <TextField
                label={param1Label}
                name="rowsQuantity"
                value={newRowRows}
                onChange={setRowsQuantity}
                margin="normal"
                variant="outlined"
                autoComplete="off"
                type="text"
                style={{ width: 70 }}
                inputProps={{
                  min: 0,
                  max: 100,
                  style: {
                    textAlign: "center",
                    fontSize: "20px",
                  },
                }}

                // inputProps={{ size: "3" }}
              />
              <IconButton
                color="primary"
                component="span"
                onClick={() => {
                  deltaRowsQuantity(+1);
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <IconButton
                color="primary"
                component="span"
                onClick={() => {
                  deltaModulesQuantity(-1);
                }}
              >
                <RemoveIcon />
              </IconButton>
              <TextField
                label={param2Label}
                name="modulesQuantity"
                value={newRowModules}
                onChange={setModulesQuantity}
                margin="normal"
                variant="outlined"
                autoComplete="off"
                type="text"
                style={{ width: 70 }}
                inputProps={{
                  min: 0,
                  max: 100,
                  style: {
                    textAlign: "center",
                    fontSize: "20px",
                  },
                }}

                // inputProps={{ size: "3" }}
              />
              <IconButton
                color="primary"
                component="span"
                onClick={() => {
                  deltaModulesQuantity(+1);
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" justifyContent="left">
              <Button
                fontSize="large"
                size="large"
                variant="contained"
                color="primary"
                style={{ marginTop: "1rem" }}
                onClick={addRow}
              >
                {buttonAddRowLabel}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  {gridLayout.map((row, idx) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">
                        <Typography variant="h5">
                          {row[0]} {rowText1} {row[1]} {rowText2}
                        </Typography>
                        {/* {row.rows} rows of {row.modules} modules */}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          component="span"
                          onClick={() => {
                            removeRow({ idx });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </FormControl>
    </Box>

    // </Container>
  );
};

// <TableContainer component={Paper}>
// <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
//   <TableHead>
//     <TableRow>
//       <TableCell>Dessert (100g serving)</TableCell>
//       <TableCell align="right">Calories</TableCell>
//       <TableCell align="right">Fat&nbsp;(g)</TableCell>
//       <TableCell align="right">Carbs&nbsp;(g)</TableCell>
//       <TableCell align="right">Protein&nbsp;(g)</TableCell>
//     </TableRow>
//   </TableHead>
//   <TableBody>
//     {rows.map((row) => (
//       <TableRow
//         key={row.name}
//         sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//       >
//         <TableCell component="th" scope="row">
//           {row.name}
//         </TableCell>
//         <TableCell align="right">{row.calories}</TableCell>
//         <TableCell align="right">{row.fat}</TableCell>
//         <TableCell align="right">{row.carbs}</TableCell>
//         <TableCell align="right">{row.protein}</TableCell>
//       </TableRow>
//     ))}
//   </TableBody>
// </Table>
// </TableContainer>
