import React from "react";
// import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// export const ProgressWithValueLabel(props) {

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  // colorPrimary: {
  //   backgroundColor:
  //     theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  // },
  bar: {
    borderRadius: 5,
    // backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export const ProgressWithValueLabel = (props) => {
  const classes = useStyles();
  // export default function ProgressWithValueLabel(props) {
  // function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={35}>{/* bilanca la i */}</Box>
      <Box width="100%" mr={1} className={classes.root}>
        {/* <LinearProgress variant="determinate" {...props} /> */}
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

// export default function CustomizedProgressBars() {
export function CustomizedProgressBars() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={50} />
    </div>
  );
}

// LinearProgressWithLabel.propTypes = {
//   /**
//    * The value of the progress indicator for the determinate and buffer variants.
//    * Value between 0 and 100.
//    */
//   value: PropTypes.number.isRequired,
// };

// const useStyles = makeStyles({
//   progress: {
//     width: "100%",
//   },
// });

// export default function LinearWithValueLabel() {
//   const classes = useStyles();

//   // const [progress, setProgress] = React.useState(10);

//   // React.useEffect(() => {
//   //   const timer = setInterval(() => {
//   //     setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
//   //   }, 800);
//   //   return () => {
//   //     clearInterval(timer);
//   //   };
//   // }, []);

//   return (
//     <div className={classes.progress}>
//       <LinearProgressWithLabel value={progress} />
//     </div>
//   );
// }
