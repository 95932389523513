import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { getSiteParams } from "../../configuration/wizardConfiguration";
import { evalExpression } from "../../utils/parseUtils";

// import SimpleModal from "./SimpleModal";

// import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles({
  mediaContainer: {
    height: 270,
  },
  tileDescription: {
    margin: "8px",
  },
});

export const CardsRadioGroupCollectionStep = ({
  formData,
  setForm,
  navigation,
  stepData,
}) => {
  const { radioGroupCollection } = stepData.params;
  const { img_base_url } = getSiteParams();
  const classes = useStyles();

  const makeCards = (radioGroupCollection) => {
    let cards = [];
    let visibleGroup = false;
    let repeatGroup = 0;
    let itemNumber = 0;
    for (var irg = 0; irg < radioGroupCollection.length; irg++) {
      const rg = radioGroupCollection[irg];

      visibleGroup = evalExpression(rg.visibleIf, formData);
      repeatGroup = 0;
      if (visibleGroup) {
        repeatGroup = evalExpression(rg.repeat, formData);
      }
      for (var i = 1; i <= repeatGroup; i++) {
        const g = { ...rg };
        itemNumber++;
        g.description = g.description.replace("{{NNN}}", itemNumber);
        g.description =
          g.description +
          " " +
          (itemNumber === 1 ? g.firstItemSescriptionPostfix : "");
        const placeholder = "NNNNN";
        g.name = g.name.replace("{{NNN}}", placeholder + i + placeholder);
        g.value = g.value.replace("{{NNN}}", placeholder + i + placeholder);
        g.img_alt = g.img_alt.replace("{{NNN}}", i);
        g.label = g.label.replace("{{NNN}}", i);
        cards.push(g);
      }
    }
    return cards;
  };

  return (
    <Grid container spacing={3} alignContent="center" justify="space-around">
      {makeCards(radioGroupCollection).map((group, index) => {
        return (
          <FormControl component="fieldset" key={index}>
            <RadioGroup
              key={group.name}
              aria-label={group.label}
              name={group.name}
              value={formData[group.value]}
              onChange={setForm}
            >
              <Grid item container direction="column" alignItems="center">
                <Grid
                  item
                  container
                  direction="column"
                  justify="flex-end"
                  alignItems="center"
                  className={classes.mediaContainer}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      align="center"
                      className={classes.tileDescription}
                    >
                      {group.description}
                    </Typography>
                    <img
                      src={`${img_base_url}${group.img_src}`}
                      alt={group.img_alt}
                      className="imgTileCollection"
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  {group.options.map((option, index) => {
                    let optLabel = (
                      <Box display="flex" alignItems="center">
                        <img
                          src={`${img_base_url}${option.img_src}`}
                          alt={option.label}
                          width="30"
                        />
                        <Box ml={1}>
                          <Typography variant="body1" component="span">
                            {option.label}
                          </Typography>
                        </Box>
                      </Box>
                    );
                    return (
                      <Grid item key={option.value}>
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={optLabel}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        );
      })}
    </Grid>
  );

  // return makeCards(radioGroupCollection).map((group, index) => {
  //   return (
  //     <FormControl component="fieldset" fullWidth={true} key={index}>
  //       <RadioGroup
  //         key={group.name}
  //         aria-label={group.label}
  //         name={group.name}
  //         value={formData[group.value]}
  //         onChange={setForm}
  //       >

  //         <div>{group.description}</div>
  //         <img src={group.img_src} alt={group.img_alt} />
  //         <div>
  //           {group.options.map((option, index) => {
  //             return (
  //               <FormControlLabel
  //                 key={option.value}
  //                 value={option.value}
  //                 control={<Radio />}
  //                 label={option.label}
  //               />
  //             );
  //           })}
  //         </div>
  //       </RadioGroup>
  //     </FormControl>
  //   );
  // });
};
