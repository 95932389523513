import React from 'react';

import CardMedia from '@material-ui/core/CardMedia';

// import CardMedia from '@mui/material/CardMedia';
// import Box from '@mui/material/Box';


// import Box from "@material-ui/core/Box";

export const VideoWidget = ({videoSrc}) => {
    // if (!video){
    //     return <div>Loading...</div>
    // }

    // const videoSrc= `https://www.youtube.com/embed/${video.id.videoId}` 


    // https://bit.ly/3UvjaO2
    // 7680p (8K): 7680x4320
    // 2160p (4K): 3840x2160
    // 1440p: 2560x1440
    // 1080p: 1920x1080
    // 720p: 1280x720
    // 480p: 854x480
    // 360p: 640x360
    // 240p: 426x240


    return (
        <div align="center"> 
            <CardMedia component="iframe" src={videoSrc} allow="autoPlay" width="640" height="360"  />
            {/* <CardMedia component="iframe" src={videoSrc} allow="autoPlay" width="640" height="360" /> */}
            {/* <div className="ui embed"> */}
                {/* <iframe title="Video player" src={videoSrc} /> */}
            {/* </div> */}
            {/* <div className="ui segment">                
                <h4 className="ui header">{video.snippet.title}</h4>
                <p>{video.snippet.description}</p>
            </div> */}
        </div>
    );
}

