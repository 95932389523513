import React from "react";

// import Radio from "@material-ui/core/Radio";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SimpleModal from "./SimpleModal";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Select from "@material-ui/core/Select";
import _ from "lodash";
// import { Box } from "@material-ui/core";
import { m } from "../../utils/i18n";

import { makeStyles } from "@material-ui/core/styles";

import { getSiteParams } from "../../configuration/wizardConfiguration";

// import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles({
  imgDescription: {
    marginBottom: "40px",
    marginTop: "10px",
  },
});

// const Quantity = ({ formData, setFormValues, option }) => {
//   // const { cards } = radioGroup;
//   const updateQuantity = (updatedQ) => {
//     let updatedFormData = { ...formData };

//     if (isNaN(updatedQ) || updatedQ <= 0) {
//       updatedFormData[option.value] = 0;
//       updatedFormData[`${option.value}__code`] = "";
//       updatedFormData[`${option.value}__description`] = "";
//     } else {
//       updatedFormData[option.value] = updatedQ;
//       updatedFormData[`${option.value}__code`] = option.name;
//       updatedFormData[`${option.value}__description`] = option.label;
//     }
//     setFormValues({ ...updatedFormData });
//   };

//   const deltaQuantity = (delta) => {
//     let updatedQ = formData[option.value] + delta;
//     updateQuantity(updatedQ);
//   };

//   const setQuantity = (e) => {
//     updateQuantity(parseInt(e.target.value));
//   };

//   return (
//     <Box display="flex" alignItems="center" justifyContent="center">
//       <IconButton
//         color="primary"
//         component="span"
//         onClick={() => {
//           deltaQuantity(-1);
//         }}
//       >
//         <RemoveIcon />
//       </IconButton>
//       <TextField
//         // label={option.label}
//         // label={"Q.tà"}
//         name={option.name}
//         value={formData[option.value]}
//         onChange={setQuantity}
//         margin="normal"
//         variant="outlined"
//         autoComplete="off"
//         type="text"
//         style={{ width: 70 }}
//         inputProps={{
//           min: 0,
//           max: 100,
//           style: {
//             textAlign: "center",
//             fontSize: "20px",
//           },
//         }}

//         // inputProps={{ size: "3" }}
//       />
//       <IconButton
//         color="primary"
//         component="span"
//         onClick={() => {
//           deltaQuantity(+1);
//         }}
//       >
//         <AddIcon />
//       </IconButton>
//     </Box>
//   );
// };

export const CardsSelectGroupStep = (props) => {
  const classes = useStyles();

  const { formData, stepData, setFormValues } = props;
  const { radioGroup } = stepData.params;
  const { cards } = radioGroup;
  const { img_base_url } = getSiteParams();

  const handleChange = (event) => {
    let updatedFormData = { ...formData };
    // updatedFormData[radioGroup.value] = event.target.value;

    // // Your main collection
    // const mainCollection = [
    //   {
    //     id: 1,
    //     name: "Item 1",
    //     subCollection: [
    //       { subId: 101, subName: "SubItem 1" },
    //       { subId: 102, subName: "SubItem 2" },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Item 2",
    //     subCollection: [
    //       { subId: 201, subName: "SubItem 3" },
    //       { subId: 202, subName: "SubItem 4" },
    //     ],
    //   },
    //   // ... more items
    // ];

    // // ID of the sub-item you want to find
    // const targetSubItemId = event.target.value;

    // // Using lodash's find to search for the sub-item in each main item's subCollection
    // const foundItem = _.find(mainCollection, (item) =>
    //   _.some(item.subCollection, { subId: targetSubItemId })
    // );

    // // Check if the item with the sub-item was found
    // if (foundItem) {
    //   console.log("Found item:", foundItem);
    // } else {
    //   console.log("Item not found.");
    // }

    // // se c'è codice item lo mettiamo in formData
    // const opt = _.find(radioGroup.options, { value: event.target.value });
    // if (_.has(opt, "code") && !_.isEmpty(opt.code)) {
    //   updatedFormData[`${radioGroup.name}___code`] = opt.code;
    // }

    const card = _.find(radioGroup.cards, (item) =>
      _.some(item.options, { value: event.target.value })
    );
    updatedFormData[card.code] = event.target.value;

    setFormValues({ ...updatedFormData });
  };

  return (
    <FormControl component="fieldset" fullWidth={true}>
      {/* <FormLabel component="legend">Brand</FormLabel> */}
      {/* <RadioGroup
        aria-label={radioGroup.label}
        name={radioGroup.name}
        value={formData[radioGroup.value]}
        onChange={setForm}
      > */}

      <Grid
        item
        container
        direction="column"
        justify="space-around"
        alignItems="stretch"
      >
        <Grid
          item
          container
          spacing={1}
          alignContent="center"
          alignItems="flex-start"
          justify="space-around"
        >
          {cards.map((card, card_index) => {
            const modal =
              card.infoText && card.infoText !== "" ? (
                <SimpleModal
                  {...{ title: card.description, content: card.infoText }}
                />
              ) : (
                ""
              );

            const nb =
              card.nbText && card.nbText !== "" ? (
                <Box
                  display="flex"
                  color="red"
                  width="100%"
                  justifyContent="center"
                  pt={3}
                >
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 600 }}
                    align="center"
                  >
                    {card.nbText}
                  </Typography>
                </Box>
              ) : (
                ""
              );
            const imgDisclaimer =
              card.imgDisclaimer && card.imgDisclaimer !== "" ? (
                <Typography variant="caption" align="center">
                  {card.imgDisclaimer}
                </Typography>
              ) : (
                ""
              );
            return (
              <Grid
                item
                container
                xs={12}
                sm={6}
                md={4}
                lg={3}
                direction="column"
                spacing={1}
                key={card_index}
              >
                <Grid
                  item
                  container
                  direction="column"
                  justify="flex-end"
                  alignItems="center"
                  className="mediaContainer"
                >
                  <img
                    src={`${img_base_url}${card.img_src}`}
                    alt={card.img_alt}
                    className="imgTile"
                  />
                </Grid>
                <Grid item container align="center" direction="row">
                  {imgDisclaimer}
                  <div>{card.description}</div>

                  <Select
                    labelId={card.label}
                    id={card.code}
                    value={formData[card.code]}
                    onChange={handleChange}
                    // onChange={(event, child) => {
                    //   handleChange(event, child);
                    // }}
                    displayEmpty
                    className={classes.selectEmpty}
                    IconComponent={(props) => (
                      <ArrowDropDownIcon fontSize="large" {...props} />
                    )}
                  >
                    <MenuItem value="">
                      <em>{m("common.select.placeholder")}</em>
                    </MenuItem>
                    {card.options.map((option) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}{" "}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  {/* {card.options.map((option, option_index) => {
                    return (
                      <Grid container item>
                        <Grid>
                          <Typography variant="caption" align="center">
                            {option.label}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Quantity {...{ formData, setFormValues, option }} />
                        </Grid>
                      </Grid>
                    );
                  })} */}

                  {/* <FormControlLabel
                    key={card.value}
                    value={card.value}
                    control={<Radio />}
                    label={card.label}
                  /> */}
                  <div>{modal}</div>
                  <div>{nb}</div>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      {/* </RadioGroup> */}
    </FormControl>
  );
};
