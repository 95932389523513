import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import SimpleModal from "./SimpleModal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { getSiteParams } from "../../configuration/wizardConfiguration";
import ReactMarkdown from "react-markdown";


const useStyles = makeStyles((theme) => ({
  clickable: {
    cursor: 'pointer',
    width: "100%",
  },
}));

export const CardsRadioGroupStep = ({
  formData,
  setFormValues,
  setForm,
  navigation,
  stepData,
  steps,
}) => {
  const { img_base_url } = getSiteParams();
  const { radioGroup, nbText } = stepData.params;
  const { options } = radioGroup;
  const classes = useStyles();


  const handleChange = (
    radioGroup,
    event,
    formData,
    stepData,
    setFormValues
  ) => {
    const { name, options } = radioGroup;
    const { value } = event.target;
    let updatedFormData = { ...formData };

    updatedFormData[name] = event.target.value;

    // se c'è codice item lo mettiamo in formData
    const opt = _.find(options, { value: value });
    if (_.has(opt, "code") && !_.isEmpty(opt.code)) {
      updatedFormData[`${name}___code`] = opt.code;
    }
    // se c'è price item lo mettiamo in formData
    if (_.has(opt, "price") && !_.isEmpty(opt.price)) {
      updatedFormData[`${name}___price`] = opt.price;
    }
    // se c'è jumptostep item lo mettiamo in formData
    if (_.has(opt, "jumptostep") && !_.isEmpty(opt.jumptostep)) {
      updatedFormData[`${name}___jumptostep`] = opt.jumptostep;
    }
    setFormValues({ ...updatedFormData });
  };

  // if (options.length === 0) {
  //   console.log("vuoto");
  //   navigation.next();
  //   console.log("vuoto");
  // }

  // const handleImageClick = (card) => {
  //   // Handle the click event here
  //   console.log('Image clicked!');
  // };

  const handleImageClick = (
    radioGroup,
    card,
    formData,
    stepData,
    setFormValues
  ) => {
    const { name, options } = radioGroup;
    const { value } = card;
    let updatedFormData = { ...formData };

    updatedFormData[name] = card.value;

    // se c'è codice item lo mettiamo in formData
    const opt = _.find(options, { value: value });
    if (_.has(opt, "code") && !_.isEmpty(opt.code)) {
      updatedFormData[`${name}___code`] = opt.code;
    }
    // se c'è price item lo mettiamo in formData
    if (_.has(opt, "price") && !_.isEmpty(opt.price)) {
      updatedFormData[`${name}___price`] = opt.price;
    }
    // se c'è jumptostep item lo mettiamo in formData
    if (_.has(opt, "jumptostep") && !_.isEmpty(opt.jumptostep)) {
      updatedFormData[`${name}___jumptostep`] = opt.jumptostep;
    }
    setFormValues({ ...updatedFormData });
  };



  return (
    <FormControl component="fieldset" fullWidth={true}>
      {/* <FormLabel component="legend">Brand</FormLabel> */}
      <RadioGroup
        aria-label={radioGroup.label}
        name={radioGroup.name}
        value={formData[radioGroup.value]}
        // onChange={setForm}
        onChange={(e) => {
          handleChange(radioGroup, e, formData, stepData, setFormValues);
        }}
      >
        {/* Riga items e riga nb */}
        <Grid
          item
          container
          direction="column"
          justify="space-around"
          alignItems="stretch"
        >
          {options.length === 0 ? (
            <Grid
              item
              container
              spacing={1}
              alignContent="center"
              alignItems="flex-start"
              justify="space-around"
            >
              <Box
                display="flex"
                width="50%"
                justifyContent="center"
                m={2}
                px={2}
              >
                <Typography variant="h6" align="center">
                  <ReactMarkdown
                    children={stepData.params.messageUnavailable}
                  />
                </Typography>
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              container
              spacing={1}
              alignContent="center"
              alignItems="flex-start"
              justify="space-around"
            >
              {options.map((card, card_index) => {
                // const props = { title: card.label, content: card.infoText };
                const modal =
                  card.infoText && card.infoText !== "" ? (
                    <SimpleModal
                      {...{ title: card.label, content: card.infoText }}
                    />
                  ) : (
                    ""
                  );

                const nb =
                  card.nbText && card.nbText !== "" ? (
                    <Box
                      display="flex"
                      color="red"
                      width="100%"
                      justifyContent="center"
                      pt={3}
                    >
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 600 }}
                        align="center"
                      >
                        {card.nbText}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  );
                const imgDisclaimer =
                  card.imgDisclaimer && card.imgDisclaimer !== "" ? (
                    <Typography variant="caption" align="center">
                      {card.imgDisclaimer}
                    </Typography>
                  ) : (
                    ""
                  );

                return (
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    direction="column"
                    spacing={1}
                    key={card_index}
                  >
                    <Grid
                      item
                      container
                      direction="column"
                      justify="flex-end"
                      alignItems="center"
                      className="mediaContainer"
                    >
                      
                      <img
                        className={classes.clickable} 
                        src={`${img_base_url}${card.img_src}`}
                        alt={card.img_alt}
                        // className="imgTile"
                        onClick={(e) => {
                          handleImageClick(radioGroup, card, formData, stepData, setFormValues);
                        }}
                        // onClick={(e) => {
                        //   handleImageClick(card
                        //   );
                        // onChange={(e) => {
                        //   handleChange(radioGroup, e, formData, stepData, setFormValues);
                        // }}
      
                          // handleImageClick(card
                          //   optionGroup,
                          //   e,
                          //   formData,
                          //   stepData,
                          //   setFormValues
                          // );



                        // }}




                      />
                      
                    </Grid>
                    <Grid item align="center">
                      {imgDisclaimer}
                      <Typography variant="h6" align="center">
                        {card.description}
                      </Typography>
                      <FormControlLabel
                        key={card.value}
                        value={card.value}
                        control={<Radio />}
                        label={card.label}
                      />
                      <div>{modal}</div>
                      <div>{nb}</div>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )}

          {nbText ? (
            <Grid item align="center">
              <Box
                display="flex"
                color="red"
                width="50%"
                justifyContent="center"
                m={2}
                px={2}
              >
                <Typography variant="h6" align="center">
                  {nbText}
                </Typography>
              </Box>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

// return (
//   <Container maxWidth="md">
//     <FormControl component="fieldset">
//       {/* <FormLabel component="legend">Brand</FormLabel> */}
//       <RadioGroup
//         aria-label={radioGroup.label}
//         name={radioGroup.name}
//         value={formData[radioGroup.value]}
//         onChange={setForm}
//       >
//         {options.map((card, card_index) => {
//           const modal =
//             card.infoText && card.infoText !== "" ? <SimpleModal /> : "";

//           return (
//             <div key={card_index}>
//               <img
//                 src={`${img_base_url}${card.img_src}`}
//                 alt={card.img_alt}
//               />
//               <div>{card.description}</div>
//               <FormControlLabel
//                 key={card.value}
//                 value={card.value}
//                 control={<Radio />}
//                 label={card.label}
//               />
//               <div>{modal}</div>
//             </div>
//           );
//         })}
//       </RadioGroup>
//     </FormControl>
//   </Container>
// );
